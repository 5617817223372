import { combineReducers } from 'redux';
import favourites from './favourites';
import { user } from './user';

const rootReducer = combineReducers({
    favourites,
    user
});

export default rootReducer;
