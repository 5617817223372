import React from 'react';
import { render } from 'react-dom';
import Modal from '../common/components/modal/Modal';
import LoginContainer from '../area/login/components/LoginContainer';

export default (domNodeList) => {
    [...domNodeList].forEach((button) => {
        button.addEventListener('click', (event) => {
            event.preventDefault();

            const returnLink = event.currentTarget.dataset.link || '';
            const type = event.currentTarget.dataset.type || '';
            const trigger = event.currentTarget.dataset.trigger || '';
            const headerMessage = event.currentTarget.dataset.message || '';
            const onSuccessAction = event.currentTarget.dataset.successaction || '';

            render(
                <Modal show={true}>
                    <LoginContainer
                        headerMessage={headerMessage}
                        returnLink={returnLink}
                        type={type}
                        trigger={trigger}
                        onSuccessAction={onSuccessAction}
                    />
                </Modal>, document.getElementById('loginRoot')
            );
        });
    });
};
