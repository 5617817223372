const valid = require('card-validator');

export const validateRequired = (input) => {
    if (input.required) {
        if (input.type === 'checkbox') {
            return input.checked === false;
        }
        return input.value === '';
    }
    return false;
};

export const validateRequiredCheckbox = input => input.checked;

export const validatePattern = input => new RegExp(input.pattern).test(input.value);

export const validateCVV = input => valid.cvv(input.value, input.maxLength).isValid;

export const validateCardNumber = input => valid.number(input.value).isValid;

export const validateExpiryDate = input => valid.expirationDate(input).isValid;
