import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loadJS } from '../../../utilities/loadJs';
import { facebookSDKUrl } from '../../../common/constants/thirdPartyJs';
import api from '../../../common/services/api';
import Button from '../../../common/components/restyled/Button';
import Icon from '../../../common/components/restyled/Icon';
import {
    LoginModes
} from '../constants/login';

class FacebookLogin extends Component {
    static get propTypes() {
        return {
            onSuccess: PropTypes.func,
            mode: PropTypes.string,
        };
    }

    static defaultProps = {
        onSuccess: () => {},
        mode: LoginModes.login,
    }

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: ''
        };

        this.login = this.login.bind(this);
    }

    componentDidMount() {
        loadJS(facebookSDKUrl, () => {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: window.SP.env.fbId,
                    status: true,
                    xfbml: true,
                },
                { scope: 'email' });
            };
        });
    }

    onFacebookSuccess() {
        const { onSuccess, mode } = this.props;
        // here you can find what fields are available to get https://developers.facebook.com/docs/graph-api/reference/user
        window.FB.api('/me', { fields: 'first_name, email' }, (response) => {
            const userProfile = {
                firstName: response.first_name,
                email: response.email
            }
            mode === LoginModes.signup ? onSuccess('social', userProfile) : onSuccess();
            // createCookie('loginChannel', LOGIN_CHANNEL_FACEBOOK, 30);
        });
    }

    login() {
        window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                // connected
                console.log('Already connected, redirect to login page to create token.');
                api.socialLogin(response.authResponse.accessToken, 'facebook')
                    .then((data) => {
                        const { status, message } = data;

                        if (status === 'Unauthorized') {
                            this.setState({errorMessage: message });
                        } else {
                            this.onFacebookSuccess();
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                // not_authorized
                window.FB.login((loginResponse) => {
                    if (loginResponse.authResponse) {
                        api.socialLogin(loginResponse.authResponse.accessToken, 'facebook')
                            .then((data) => {
                                const { status, message } = data;
                                if (status === 'Unauthorized') {
                                    this.setState({errorMessage: message });
                                } else {
                                    this.onFacebookSuccess();
                                    console.log('Facebook Login Successful');
                                }
                            })
                            .catch((error) => {
                                console.error('Error: Not Authorised', error);
                            });
                    } else {
                        console.log('Cancelled.');
                    }
                }, { scope: 'email, public_profile' });
            }
        });
    }

    render() {
        const { errorMessage } = this.state;

        return (
            <>
                <Button
                    displayStyle="secondary"
                    width="full"
                    icons={{
                        left: <Icon symbol="facebook" />,
                        right: <Icon symbol="arrow-right" />,
                    }}
                    onClick={this.login}
                >
                    Continue with Facebook
                </Button>

                { errorMessage && (
                    <p className="c-authentication__error">{errorMessage}</p>)
                }
            </>
        );
    }
}

export default FacebookLogin;
