import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import globalStore from '../../../app/reduxGlobalStore';
import FavouritesButton from '../../../common/components/favourites/FavouritesButton';

const favouritesButtonMap = new WeakMap();

const renderFavouritesButton = () => {
    Array.from(document.querySelectorAll('.favourites-button')).forEach((button) => {
        if (!favouritesButtonMap.has(button)) {
            const hasText = button.getAttribute('data-has-text');
            const propertyId = button.getAttribute('data-property-id');
            const propertyType = button.getAttribute('data-property-type');

            favouritesButtonMap.set(button, true);

            render(
                <Provider store={globalStore}>
                    <FavouritesButton itemId={propertyId} itemType={propertyType} showButtonText={hasText} />
                </Provider>,
                button
            );
        }
    });
};

export default renderFavouritesButton;
