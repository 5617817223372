import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../restyled/Button';
import InputTypes from '../InputTypes';
import Input from '../restyled/Input';
import removeSpecialCharacters from '../../helpers/favourites/removeSpecialCharacters';

export default class CreateFavouriteList extends Component {
    /**
     * propTypes
     * @property {string} title the title of the component
     * @property {object} formErrors state for errord set in withFormValidation
     * @property {function} validateForm function coming as props from withFormValidation
     * @property {function} createList function coming as props from FavouriteModalStages
     * @property {function} setStageToView function to set the state to view, once the new FavouriteList has been successfully added
     * @property {function} cancelCreateList the function which runs when we click the cancel button
     */

    static get propTypes() {
        return {
            title: PropTypes.string.isRequired,
            formErrors: PropTypes.objectOf(PropTypes.string).isRequired,
            validateForm: PropTypes.func.isRequired,
            createList: PropTypes.func.isRequired,
            setStageToView: PropTypes.func.isRequired,
            cancelCreateList: PropTypes.func,
        };
    }

    static defaultProps = {
        cancelCreateList: () => {},
    }

    constructor(props) {
        super(props);

        this.state = {
            listName: '',
            hasErrors: false,
            errorMessage: ''
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.submitList = this.submitList.bind(this);
    }

    onInputChange(event) {
        // remove new line, carriage return and multiple spaces
        const listName = removeSpecialCharacters(event.target.value);
        this.setState({
            listName,
            // this is to remove the errorMEssage we get from the API response
            hasErrors: false,
            errorMessage: ''
        });
    }

    submitList(event) {
        event.preventDefault();

        const { validateForm, createList, setStageToView } = this.props;
        const { listName } = this.state;
        const form = event.target.closest('form');

        validateForm(form).then((errors) => {
            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                let accumulator = acc;
                if (errors[curr] !== '') {
                    accumulator += 1;
                }
                return accumulator;
            }, 0);

            if (numberOfErrors === 0) {
                const favouritesForm = {
                    wishlistTitle: listName,
                    description: ''
                };

                createList(favouritesForm).then(() => {
                    this.setState({
                        hasErrors: false,
                        errorMessage: ''
                    }, () => setStageToView(true));
                }).catch((error) => {
                    this.setState({
                        hasErrors: true,
                        errorMessage: error
                    });
                });
            }
        });
    }

    render() {
        const { title, cancelCreateList, formErrors } = this.props;
        const { listName, hasErrors, errorMessage } = this.state;
        const inputClass = hasErrors ? 'c-form__field--hasError p-favourites' : 'p-favourites';

        return (
            <div className="c-favourites" data-stage="create">
                <h2 className="c-favourites__title">{title}</h2>
                <form className="c-favourites__form">
                   <Input
                        name="listName"
                        required={true}
                        value={listName}
                        label="Type a title"
                        placeholder="e.g. Smith weekend or Summer holiday"
                        error={formErrors?.listName?.length > 0 && listName.length === 0}
                        onChange={this.onInputChange}
                    />
                    {hasErrors
                    && (<p className="c-favourites__errors">{errorMessage}</p>)
                    }
                    <div className="flex-row-justify c-favourites__buttons">
                        <div className="S-2_4 M-6_12 L-6_12 XL-6_12 XXL-6_12">
                            <Button
                                displayStyle="secondary"
                                width="full"
                                onClick={cancelCreateList}
                                text="cancel"
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className="S-2_4 M-6_12 L-6_12 XL-6_12 XXL-6_12">
                            <Button
                                displayStyle="primary"
                                width="full"
                                onClick={this.submitList}
                                text="create"
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
