export const checkCharactersLength = (value: string, length: number) => {
    return value.length >= length;
};

const isALetter = (character: string) => {
    return character.match(/[a-zA-Z]/i);
};

const hasNoSpace = (character: string) => {
    return character.match(/\s/);
};

export const isLowerCase = (character: string) => {
    return isALetter(character) && character === character.toLowerCase();
};

export const isUpperCase = (character: string) => {
    return isALetter(character) && character === character.toUpperCase();
};

export const isANumber = (character: string) => {
    return character.match(/[0-9]/i);
};

export const isSpecialCharacter = (character: string) => {
    return !isANumber(character) && !isALetter(character) && !hasNoSpace(character);
};

export const checkCharacterType = (characters: string, characterTypeFN: Function) => {
    let isCharacterType = false;
    if (characters.length > 0) {
        for (let i = 0; i <= characters.length; i++) {
            if (characters[i] !== undefined && characterTypeFN(characters[i])) {
                isCharacterType = true;
                return isCharacterType;
            }
        }
    }
    return isCharacterType;
};
