import React, { Component } from 'react';
import { mapErrorsToObj } from '../../area/booking/modelBuilders/formModelBuilder';

export default (WrappedComponent, validationService) =>
    class FormWithValidation extends Component {
        static displayName = `withFormValidation(${WrappedComponent.displayName || WrappedComponent.name})`;

        constructor(props, context) {
            super(props, context);

            this.state = {
                formErrors: {}
            };

            this.onSubmitValidation = this.onSubmitValidation.bind(this);
            this.onBlurValidation = this.onBlurValidation.bind(this);
            this.validateField = this.validateField.bind(this);
        }

        validateField(input) {
            const fieldError = validationService.validateField(input);
            const { formErrors } = this.state;

            this.setState({
                formErrors: Object.assign({}, formErrors, fieldError)
            });
        }

        onBlurValidation(event) {
            const input = event.target;
            this.validateField(input);
        }

        onSubmitValidation(form) {
            const formErrors = validationService.validateForm(form);

            const errors = mapErrorsToObj(formErrors);
            this.setState({
                formErrors: Object.assign({}, errors)
            });

            return Promise.resolve(errors);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    onBlurValidation={this.onBlurValidation}
                    validateForm={this.onSubmitValidation}
                    validateField={this.validateField}
                />
            );
        }
    };
