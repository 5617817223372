import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { loadJS } from '../../../utilities/loadJs';
import { GOOGLE_LOGIN_URL, GOOGLE_CLIENT_ID } from '../../../common/constants/thirdPartyJs';
import api from '../../../common/services/api';
import Button from '../../../common/components/restyled/Button';
import Icon from '../../../common/components/restyled/Icon';
import {
    LoginModes
} from '../constants/login';

class GoogleLogin extends Component {
    static get propTypes() {
        return {
            onSuccess: PropTypes.func,
            mode: PropTypes.string,
        };
    }

    static defaultProps = {
        onSuccess: () => {},
        mode: LoginModes.login,
    }

    constructor(props) {
        super(props);

        this.state = {
            error: false
        }

        this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
        this.onGoogleFailure = this.onGoogleFailure.bind(this);
    }

    componentDidMount() {
        loadJS(GOOGLE_LOGIN_URL, () => {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: `${GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
                    scope: 'profile email',
                    response_type: 'id_token permission',
                    ux_mode: 'popup' // redirect
                }).then(() => {
                    const GoogleAuth = window.gapi.auth2.getAuthInstance();
                    const options = new window.gapi.auth2.SigninOptionsBuilder();
                    options.setPrompt('select_account');
                    options.setScope('profile').setScope('email');
                    GoogleAuth.attachClickHandler('google-root', options, this.onGoogleSuccess, this.onGoogleFailure);
                });
            });
        });
    }


    onGoogleSuccess(googleUser) {
        const { onSuccess, mode } = this.props;
        const profile = googleUser.getBasicProfile();

        const userProfile = {
            firstName: profile.getGivenName(),
            email: profile.getEmail(),
            imgSrc: profile.getImageUrl(),
        }
        // this is the token that needs to be sent to BE
        const { id_token: idToken } = googleUser.getAuthResponse();
        api.socialLogin(idToken, 'google').then(() => {
            mode === LoginModes.signup ? onSuccess('social', userProfile) : onSuccess();
            // createCookie('loginChannel', LOGIN_CHANNEL_GOOGLE, 30);
        });
    }

    onGoogleFailure() {
        this.setState({ error: true });
    }

    render() {
        const { error } = this.state;
        return (
            <Fragment>
                <div id="google-root">
                    <Button
                        displayStyle="secondary"
                        width="full"
                        icons={{
                            left: <Icon symbol="google" />,
                            right: <Icon symbol="arrow-right" />,
                        }}
                    >
                        Continue with Google
                    </Button>
                </div>

                {error && <p className="c-authentication__error">Google authentication failed, please try again!</p>}
            </Fragment>
        );
    }
}

export default GoogleLogin;
