const mapBoolToCharYorN = bool => bool === true ? 'Y' : 'N';

export default preferencesData => Object.keys(preferencesData).reduce((acc, curr) => {
    if (curr === 'emails') {
        preferencesData[curr].forEach((email) => {
            acc[`newsletter[${email.id}][id]`] = email.id;
            acc[`newsletter[${email.id}][newsletter]`] = mapBoolToCharYorN(email.newsletter);
            acc[`newsletter[${email.id}][email]`] = email.email;
        });
    } else {
        acc[curr] = mapBoolToCharYorN(preferencesData[curr]);
    }

    return acc;
}, Object.create(null));
