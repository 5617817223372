import React, { Component } from 'react';
import { UiNotificationWithBodyClickhandler } from '../components/UiNotification';

export default WrappedComponent => class Notification extends Component {
        static displayName = `withNotification(${WrappedComponent.displayName || WrappedComponent.name})`;

        constructor(props, context) {
            super(props, context);
            this.state = {
                arrowPositionTriangle: {
                    left: 0,
                    right: 0
                },
            };
            this.setNotificationPointerPosition = this.setNotificationPointerPosition.bind(this);
            this.getWrappedComponentWidth = this.getWrappedComponentWidth.bind(this);
        }

        componentDidMount() {
            this.setNotificationPointerPosition();  
        }

        /**
         * Returns half the value of offset width of a DOM element
         * @method
         */
        getWrappedComponentWidth() {
            return Math.round(this.element.offsetWidth / 2);
        }

        setNotificationPointerPosition() {
            const { arrowPositionOption } = this.props;

            const elementWidthCenter = this.getWrappedComponentWidth();

            if (arrowPositionOption) {
                this.setState({
                    arrowPositionTriangle: {
                        right: arrowPositionOption === 'right' ? elementWidthCenter : 'auto',
                        left: arrowPositionOption === 'left' ? elementWidthCenter : 'auto'
                    }
                });
            }
        }
        
        render() {
            const {
                children,
                type,
                showNotification,
                arrowPosition,
                notificationRef,
                arrowPositionOption,
                customClassName,
                hasCloseButton,
                closeNotification,
                ...wrappedComponentProps
            } = this.props;

            const { arrowPositionTriangle } = this.state;
            return (
                <div ref={notificationRef} className="c-notificationElement">
                    <div className="c-notificationElement__elementWrapper" ref={(nodeElement) => { this.element = nodeElement; }}>
                        <WrappedComponent {...wrappedComponentProps} />
                    </div>
                    {showNotification &&
                    (
                        <UiNotificationWithBodyClickhandler
                            type={type}
                            arrowPosition={arrowPositionTriangle}
                            arrowPositionOption={arrowPositionOption}
                            customClassName={customClassName}
                            hasCloseButton={hasCloseButton}
                            closeNotification={closeNotification}
                            handleOutsideClick={closeNotification}
                        >
                            {children}
                        </UiNotificationWithBodyClickhandler>
                    )
                    }  
                </div>
            );
        }
};
