import React from 'react';

const PasswordShown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="#A2A2A2">
      <path d="M15.28 6.41l.287.41-.046.031-.263.383c-1.894 2.75-4.4 4.144-7.475 4.144l-.089-.001 3.652-6.541c.365.616.574 1.335.574 2.103 0 1.115-.442 2.127-1.16 2.871 1.368-.569 2.557-1.567 3.575-3l.03.041-.129-.18c-.764-1.023-1.625-1.827-2.574-2.402l.488-.874c1.175.707 2.226 1.719 3.13 3.015zM7.515 2.2L3.97 8.546c-.209-.494-.324-1.037-.324-1.607 0-1.408.703-2.652 1.778-3.399l-.127.04c-1.583.526-2.939 1.59-4.08 3.205l-.067-.093.177.25c.687.94 1.45 1.684 2.29 2.237l-.488.875C2.07 9.369 1.121 8.42.292 7.209l-.283-.412L0 6.792l.282-.412c1.842-2.695 4.265-4.1 7.232-4.18zM4.28 13.925L3.407 13.438 10.907 0 11.78.487z" transform="translate(8 9.3)"/>
    </g>
  </svg>
);

export default PasswordShown;
