/**
 * Searches global data layer array for an object
 * @param {array} dataLayerArray the global datalayer array
 * @param {string} objName name of object to retrieve from the data layer
 */
export default (dataLayerArray, objName) => {
    for (let i = 0, length = dataLayerArray.length; i < length; i += 1) {
        if (dataLayerArray[i][objName] !== undefined) {
            return dataLayerArray[i][objName];
        }
    }
    return null;
};
