import React, { forwardRef, useState } from 'react';
import Icon from './Icon';
import classNames from 'classnames';

type InputProps = React.HTMLProps<HTMLInputElement> & {
    type?: 'text' | 'password' | 'email';
    complete?: boolean;
    error?: boolean | string;
    customSuccess?: string;
    displayError?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            name,
            label,
            id,
            type = 'text',
            placeholder = '',
            complete = false,
            error = false,
            customSuccess = '',
            displayError = true,
            ...inputAttr
        },
        ref
    ) => {
        const [showPassword, setShowPassword] = useState(false);
        const [inputType, setInputType] = useState(type);

        const handlePasswordInput = () => {
            const toggleType = inputType === 'password' ? 'text' : 'password';
            setShowPassword(!showPassword);
            setInputType(toggleType);
        };


        const icon = complete ? 'check' : 'info-circle';
        const passwordIcon = showPassword ? 'view' : 'view-off';
        const isPassword = type === 'password';

        return (
            <label htmlFor={id || name} className={classNames('fw-input label', (complete || error) && 'with-icons')}>
                {label}
                <div className='input-wrapper'>
                    <input
                        {...inputAttr}
                        id={id || name}
                        name={name}
                        aria-label={name}
                        type={inputType}
                        placeholder={placeholder}
                        className={classNames('input-wrapper', {
                            ['password-error']: isPassword && displayError && error !== false,
                            ['password-success']: isPassword && complete,
                        })}
                        ref={ref}
                    />
                    {(complete || error && displayError) && !isPassword && (
                        <div className='icon'>
                            <Icon
                                symbol={icon}
                                className={classNames({
                                    ['complete']: icon === 'check',
                                    ['error']: icon === 'info-circle',
                                })}
                            />
                        </div>
                    )}
                    {isPassword && (
                        <button aria-hidden type='button' tabIndex={-1} className='icon' onClick={() => handlePasswordInput()}>
                            <Icon symbol={passwordIcon} />
                        </button>
                    )}
                </div>
                {displayError && error && <p className='error-message'>{error}</p>}
                {complete && customSuccess !== '' && <p className='complete'>{customSuccess}</p>}
            </label>
        );
    }
);

export default Input;
