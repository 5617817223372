import { batchActions } from 'redux-batched-actions';
import makeActionCreator from '../../utilities/makeActionCreator';
import api from '../services/api';
import * as actionTypes from './globalActionTypes';
import { FavouriteModel } from '../helpers/favourites/FavouriteModel';
import getFavouriteModel from '../helpers/favourites/getFavouriteModel';

const favouriteItemsAction = makeActionCreator(actionTypes.INIT_FAVOURITES_LISTS, 'payload');
const createFavouritesListAction = makeActionCreator(actionTypes.ADD_FAVOURITE_LIST, 'payload');
const editFavouriteListAction = makeActionCreator(actionTypes.EDIT_FAVOURITE_LIST, 'listData');
const deleteFavouriteListAction = makeActionCreator(actionTypes.DELETE_FAVOURITE_LIST, 'listId');
const addItemToFavouritesAction = makeActionCreator(actionTypes.ADD_ITEM_TO_FAVOURITE_LIST, 'payload');
const removeItemFromFavouritesAction = makeActionCreator(actionTypes.REMOVE_ITEM_FROM_FAVOURITE_LIST, 'payload');
const getItemDataAction = makeActionCreator(actionTypes.GET_ITEM_DATA, 'payload');
const clearItemDataAction = makeActionCreator(actionTypes.CLEAR_ITEM_DATA);
const fetchResolvedAction = makeActionCreator(actionTypes.FETCHED_RESOLVED, 'isDataFetched');

export const getFavouriteLists = user => dispatch => api.getWishlist({ reference: user.reference })
    .then((response) => {
        const items = getFavouriteModel(response.wishlists);
        const areFavouritesFetched = true;

        return dispatch(batchActions([
            favouriteItemsAction(items),
            fetchResolvedAction(areFavouritesFetched)
        ]));
    });

export const createFavouriteList = newList => dispatch => api.createWishList(newList)
    .then((response) => {
        if (response.status) {
            const { id, title, url } = response.data;
            const newFavouriteList = new FavouriteModel(id.toString(), title, url);
            return dispatch(createFavouritesListAction(newFavouriteList));
        }
        throw response.errors[0];
    });

export const editFavouriteList = ({ listUrl, wishlistTitle, description }) => dispatch => api.editWishListsDetail(listUrl, wishlistTitle, description)
    .then((response) => {
        if (response.status) {
            return dispatch(editFavouriteListAction(response.data));
        }
        throw response.errors[0];
    });

export const deleteFavouriteList = data => dispatch => api.removeWishlist(data.listUrl)
    .then((response) => {
        if (response.ok) {
            return dispatch(deleteFavouriteListAction(data.listId));
        }
        throw response.errors[0];
    });

export const addItem = data => dispatch => api.addItemToFavourites(data)
    .then((response) => {
        if (response.error_code === 'None') {
            return dispatch(addItemToFavouritesAction(data));
        }
        throw response.error_msg;
    });

export const removeItem = data => dispatch => api.removeItemFromFavourites(data)
    .then((response) => {
        if (response.error_code === 'None') {
            return dispatch(removeItemFromFavouritesAction(data));
        }
        throw response.error_msg;
    });

export const getItemData = data => dispatch => dispatch(getItemDataAction(data));

export const clearItemData = () => dispatch => Promise.resolve(dispatch(clearItemDataAction()));
