import * as validators from './validators';

const supportedFormElements = ['input', 'select', 'textarea', 'checkbox'];

const noValidate = ['voucherCode', 'loyalty'];

export const validateField = (input) => {
    let validationMessage = '';

    if (validators.validateRequired(input)) {
        validationMessage = `${input.getAttribute('aria-label') || 'This'} is a required field`;
        input.classList.remove('c-form__input--inValid', 'c-form__input--valid');
    } else if (input.pattern) {
        if (validators.validatePattern(input)) {
            input.classList.remove('c-form__input--inValid');
            validationMessage = '';
            input.classList.add('c-form__input--valid');
        } else {
            input.classList.add('c-form__input--inValid');
            validationMessage = input.getAttribute('data-error') || 'Please check this input';
        }
    } else if (input.getAttribute('data-validator')) {
        const isFieldValid = validators[input.getAttribute('data-validator')](input);
        if (isFieldValid) {
            input.classList.remove('c-form__input--inValid');
            validationMessage = '';
        } else {
            input.classList.add('c-form__input--inValid');
            validationMessage = input.getAttribute('data-error') || 'Please check this input';
        }
    }

    return {
        [input.name]: validationMessage
    };
};


export const getElements = form => {
    const elements = [];
    const formElements = Array.from(form.elements);

    formElements.forEach((element) => {
        const currentFormElement = element.nodeName.toLowerCase();

        if (supportedFormElements.indexOf(currentFormElement) !== -1) {
            elements.push(element);
        } else if (currentFormElement === 'fieldset') {
            Array.from(element.children).forEach((innerElement) => {
                elements.push(innerElement);
            });
        }
    });
    return elements;
};


export const validateForm = form =>
    getElements(form).map((element) => {
        if (noValidate.indexOf(element.name) === -1) {
            return validateField(element);
        }
    });
