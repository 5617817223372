import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import Button from '../restyled/Button';
import FavouriteList from './FavouriteList';

export default class ViewFavouriteLists extends Component {
    /**
     * propTypes
     * @property {string} title the title of the component
     * @property {arrayOf(object)} favouriteLists the favourite lists
     * @property {function} openCreateList the on click function on the create button
     * @property {string} membersWishlistURL the anchor link to the members favourites area
     * @property {object} itemData the data for the item to add or remove
     * @property {function} addItemToList the add action
     * @property {function} removeItemFromList the remove action,
     * @property {string} userReference the user reference
     */

    static get propTypes() {
        return {
            title: PropTypes.string.isRequired,
            favouriteLists: PropTypes.arrayOf(PropTypes.object).isRequired,
            openCreateList: PropTypes.func.isRequired,
            membersWishlistURL: PropTypes.string.isRequired,
            addItemToList: PropTypes.func.isRequired,
            removeItemFromList: PropTypes.func.isRequired,
            userReference: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
            itemData: PropTypes.objectOf(PropTypes.string).isRequired
        };
    }
     
    constructor(props) {
        super(props);

        this.renderList = this.renderList.bind(this);
    }

    renderList() {
        const {
            favouriteLists,
            membersWishlistURL,
            itemData,
            addItemToList,
            removeItemFromList,
            userReference
        } = this.props;


        return favouriteLists.map((list) => {
            const buttonModel = {
                mode: 'view',
                action: null,
                params: {}
            };
            const id = itemData.itemId;

            if (id) {
                buttonModel.params = {
                    memberRef: userReference,
                    propertyId: id,
                    listId: list.id
                };
                if (list.propertiesIds.indexOf(id) > -1) {
                    buttonModel.mode = 'remove';
                    buttonModel.action = removeItemFromList;
                } else {
                    buttonModel.mode = 'add';
                    buttonModel.action = addItemToList;
                }
            }

            return (
                <FavouriteList
                    key={v4()}
                    listTitle={list.title}
                    listURL={`${membersWishlistURL}/list-${list.url}`}
                    items={list.propertiesIds}
                    buttonAction={() => buttonModel.action(buttonModel.params)}
                    buttonMode={buttonModel.mode}
                    itemType={itemData.itemType}
                />);
        });
    }

    render() {
        const {
            title,
            openCreateList,
            membersWishlistURL,
        } = this.props;
        return (
            <div className="c-favourites" data-stage="view">
                <h2 className="c-favourites__title">{title}</h2>
                <ul className="c-favourites__list">
                    {this.renderList()}
                </ul>
                <div className="flex-row-justify c-favourites__buttons">
                    <div className="S-2_4 M-6_12 L-6_12 XL-6_12 XXL-6_12">
                        <Button
                            displayStyle="secondary"
                            width="full"
                            onClick={() => window.location.assign(membersWishlistURL)}
                            text="See favourites"
                        >
                            See favourites
                        </Button>
                    </div>
                    <div className="S-2_4 M-6_12 L-6_12 XL-6_12 XXL-6_12">
                        <Button
                            displayStyle="primary"
                            width="full"
                            onClick={openCreateList}
                            text="create new"
                        >
                            Create new
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
