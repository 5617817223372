const mapErrorObjToInput = [
    ['shippingaddress1', 'shipping_address[line_1]'],
    ['shippingaddress2', 'shipping_address[line_2]'],
    ['shippingcounty', 'shipping_address[county]'],
    ['shippingcity', 'shipping_address[town]'],
    ['shippingpostcode', 'shipping_address[postcode]'],
    ['shippingcountry', 'shipping_address[country]'],
    ['shippingstate', 'shipping_address[state]'],
    ['shippingAddressName', 'shipping_address[name]]'],
    ['payment[expiry_date][month]', 'payment.expiry_date.month'],
    ['payment[expiry_date][year]', 'payment.expiry_date.year'],
    ['payment[number]', 'payment[checksumCorrect]'],
    ['payment[cvv]', 'payment[cvvValid]'],
    ['payment[expiry_date][month]', 'payment[expiryMonthValid]'],
    ['payment[expiry_date][year]', 'payment[expiryYearValid]'],
    ['payment[name_on_card]', 'payment[nameValid]'],
    ['payment[name_on_card]', 'payment[name_on_card]'],
    ['shippingaddress1', 'shipping_address[line1]'],
    ['shipping_options', 'shipping_options'],
    ['billingaddress1', 'address[line1]'],
    ['billingaddress2', 'address[line_2]'],
    ['billingcounty', 'address[county]'],
    ['billingcity', 'address[town]'],
    ['billingpostcode', 'address[postcode]'],
    ['billingcountry', 'address[country]'],
    ['defaultEmail', 'member.default_identity.email']
];

const fieldToObjKeyValuePairs = [
    ['firstName', 'member[firstname]'],
    ['lastName', 'member[lastname]'],
    ['defaultEmail', 'member[default_identity][email]'],
    ['defaultPhoneNumber', 'member[default_phone_number][number]'],
    ['password', 'password'],
    ['confirmPassword', 'confirmPassword'],
    ['billingaddress1', 'address[line_1]'],
    ['billingaddress2', 'address[line_2]'],
    ['billingcounty', 'address[county]'],
    ['billingcity', 'address[town]'],
    ['billingpostcode', 'address[postcode]'],
    ['billingcountry', 'address[country]'],
    ['billingstate', 'address[state]'],
    ['arrivalTime', 'about_stay[time]'],
    ['specialRequests', 'about_stay[comment]'],
    ['shippingaddress1', 'shipping_address[line_1]'],
    ['shippingaddress2', 'shipping_address[line_2]'],
    ['shippingcounty', 'shipping_address[county]'],
    ['shippingcity', 'shipping_address[town]'],
    ['shippingpostcode', 'shipping_address[postcode]'],
    ['shippingcountry', 'shipping_address[country]'],
    ['shipping_options', 'shipping_options'],
    ['shippingstate', 'shipping_address[state]'],
    ['shippingAddressName', 'shipping_address[name]]']
];

const reverseKeyValue = keyValueArray => keyValueArray.reduce((acc, curr) => {
    acc.push([curr[1], curr[0]]);
    return acc;
}, []);

export const fieldToObjMap = new Map(fieldToObjKeyValuePairs);
export const ObjToFieldMap = new Map(reverseKeyValue(fieldToObjKeyValuePairs));
export const ErrObjToInput = new Map(reverseKeyValue(mapErrorObjToInput));

export const mapErrorsToObj = errorResponse => errorResponse.reduce((acc, curr) => {
    acc = Object.assign({}, acc, curr);
    return acc;
}, {});

export const mapServerResponseErrorsToInputs = serverErrorResponse =>
    Object.keys(serverErrorResponse).reduce((acc, cur) => {
        const inputField = ErrObjToInput.get(cur);
        if (inputField !== undefined && !acc[inputField]) {
            acc = Object.assign({}, acc, {
                [inputField]: serverErrorResponse[cur]
            });
        }
        return acc;
    }, {});

export const buildFormToPost = (formData) => {
    let queryString = '';

    Object.keys(formData).forEach((field) => {
        queryString += `&${field}=${encodeURIComponent(formData[field])}`;
    });

    return queryString;
};
