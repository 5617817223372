import React, { Component } from 'react';

export default WrappedComponent => class outsideClickHandler extends Component {
    static displayName = `withOutsideClickHandler(${WrappedComponent.displayName || WrappedComponent.name})`;

    static defaultProps = {
        handleOutsideClick: () => {}
    }

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside(event) {
        const { handleOutsideClick } = this.props;

        if (handleOutsideClick) {
            if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
                handleOutsideClick();
            }
        }
    }

    render() {
        return (
            <div ref={this.wrapperRef}>
                <WrappedComponent {...this.props} />
            </div>
        );
    }
};
