import React from 'react';

interface IProps {
    className?: string;
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
}

const Cross = ({ className, width, height, viewBox = '0 0 33 33', fill }: IProps) => (
    <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className={className}
        width={width}
        height={height}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9737 16.1432L31.6996 3.41872C32.4818 2.63662 32.4843 1.36786 31.7031 0.586815C30.9165 -0.199679 29.6539 -0.192674 28.8709 0.590297L16.145 13.3147L3.41911 0.590297C2.63692 -0.191811 1.36802 -0.194234 0.586882 0.586815C-0.199701 1.37331 -0.192696 2.63575 0.590364 3.41872L13.3163 16.1432L0.590364 28.8676C-0.191833 29.6497 -0.194255 30.9185 0.586882 31.6995C1.37347 32.486 2.63605 32.479 3.41911 31.696L16.145 18.9716L28.8709 31.696C29.6531 32.4781 30.922 32.4806 31.7031 31.6995C32.4897 30.913 32.4827 29.6506 31.6996 28.8676L18.9737 16.1432Z"
            fill={fill}
        />
    </svg>
);

export default Cross;
