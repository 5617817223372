export class FavouriteModel {
    id: string;
    title: string;
    url:string;
    propertiesIds:string[];
    constructor(id: string, title: string, url:string, propertiesIds:string[] = []) {
        this.id = id;
        this.title = title;
        this.url = url;
        this.propertiesIds = propertiesIds;
    }
}
