import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'ramda/src/compose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import equals from 'ramda/src/equals';
import withApiData from '../../hocs/withApiData';
import withNotification from '../../hocs/withNotification';
import CounterNotification from '../CounterNotification';
import {
 addItem, removeItem, clearItemData, getFavouriteLists, createFavouriteList, getItemData 
} from '../../actions/favourites';
import FavouriteModalStages from './FavouriteModalStages';

const WishListIcon = compose(withNotification, withApiData)(CounterNotification);
const shouldOpenFavourites = localStorage.getItem('openAfterLogin') === 'true';

export class FavouritesIcon extends Component {
    /**
     * propTypes
     * @property {object} actions the actions
     * @property {bool} hasItemId flag for opening the modal after login
     * @property {object} user the user data
     * @property {bool} isUserLoggedIn flag to check if the user is logged in
     * @property {string} iconType the type of the icon passed
     * @property {array} favouriteLists the array of lists we get from the globalReduxStore
    */

    static get propTypes() {
        return {
            actions: PropTypes.objectOf(PropTypes.func).isRequired,
            hasItemId: PropTypes.bool.isRequired,
            user: PropTypes.objectOf(PropTypes.oneOfType([
                PropTypes.string, PropTypes.bool
            ])).isRequired,
            isUserLoggedIn: PropTypes.bool.isRequired,
            iconType: PropTypes.string,
            favourites:
                PropTypes.objectOf(PropTypes.oneOfType([
                    PropTypes.bool, PropTypes.objectOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)
                ])),
        };
    }

    static defaultProps = {
        iconType: 'favourites',
        favourites: {},
    }

    constructor(props) {
        super(props);
        this.state = {
            openLoginModal: null,
            openFavouritesModal: shouldOpenFavourites && props.isUserLoggedIn,
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        const { actions: { updateStateWithItemData }, isUserLoggedIn } = this.props;
        localStorage.removeItem('openAfterLogin');

        if (isUserLoggedIn) {
            if (localStorage.getItem('itemData') !== null) {
                const itemDataFromStorage = JSON.parse(localStorage.getItem('itemData'));
                updateStateWithItemData(itemDataFromStorage);
                localStorage.removeItem('itemData');
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { favourites, hasItemId } = this.props;
        if (!equals(favourites, prevProps.favourites)) {
            if (hasItemId) {
                this.toggleModal();
            }
        }
    }

    toggleModal() {
        const { isUserLoggedIn } = this.props;

        if (isUserLoggedIn) {
            this.setState({
                openLoginModal: false,
                openFavouritesModal: true
            });
        } else {
            localStorage.setItem('openAfterLogin', true);
            this.setState({
                openLoginModal: true,
                openFavouritesModal: false
            });
        }
    }

    closeNotification() {
        const {
            hasItemId,
            actions: { clearItem }
        } = this.props;

        this.setState({
            openFavouritesModal: false,
        }, () => {
            if (hasItemId) {
                clearItem();
            }
        });
    }

    renderFavouritesContent() {
        const {
            isUserLoggedIn,
            user,
            favourites: { favouriteLists, itemData, hasDataLoaded },
            actions: {
                createList, addItemToList, removeItemFromList
            }
        } = this.props;

        if (isUserLoggedIn) {
            if (hasDataLoaded) {
                return (
                    <FavouriteModalStages
                        user={user}
                        favouriteLists={favouriteLists}
                        createFavouriteList={createList}
                        itemData={itemData}
                        addItemToList={addItemToList}
                        removeItemFromList={removeItemFromList}
                        closeNotification={this.closeNotification}
                    />
                );
            }
            return (<div className="c-favourites__loading">Loading ...</div>);
        }
        return null;
    }

    render() {
        const {
            openFavouritesModal,
            openLoginModal,
        } = this.state;

        const {
            user,
            iconType,
            favourites: { favouriteLists },
            actions: { getLists }
        } = this.props;

        return (
            <React.Fragment>
                <WishListIcon
                    type="modal"
                    hasCloseButton={true}
                    showNotification={openFavouritesModal}
                    arrowPositionOption="right"
                    closeNotification={this.closeNotification}
                    iconType={iconType}
                    toggleModal={this.toggleModal}
                    items={favouriteLists}
                    getFavouriteLists={getLists}
                    user={user}
                >
                    {this.renderFavouritesContent()}
                </WishListIcon>
            </React.Fragment>
        );
    }
}

export default connect(
    state => ({
        favourites: state.favourites,
        hasItemId: Object.keys(state.favourites.itemData).length !== 0,
        hasDataLoaded: state.hasDataLoaded,
        user: state.user,
        isUserLoggedIn: state.user.reference !== false
    }),
    dispatch => ({
        actions: {
            getLists: bindActionCreators(getFavouriteLists, dispatch),
            createList: bindActionCreators(createFavouriteList, dispatch),
            clearItem: bindActionCreators(clearItemData, dispatch),
            addItemToList: bindActionCreators(addItem, dispatch),
            removeItemFromList: bindActionCreators(removeItem, dispatch),
            updateStateWithItemData: bindActionCreators(getItemData, dispatch)
        }
    })
)(FavouritesIcon);
