export default {
    search: 'SmithCoreSearchBundle_listingSearch',
    autosuggest: 'FamilySearchBundle_autosuggest',
    combinedtags: 'FamilySearchBundle_combinedTags',
    quickview: 'SmithCoreSearchBundle_quickView',
    basket: 'FamilyBookingBundle_viewBasket',
    member: 'FamilySystemBundle_memberData',
    rateterms: 'FamilyProviderBundle_termsAndConditions',
    images: 'FamilySystemBundle_imageByCode',
    gallery: 'FamilySystemBundle_getImages',
    availability: 'SmithCoreProviderBundle_rawCalendarAvailability',

    // search prompt labels
    'search-prompts': 'FamilySearchBundle_getSearchTerms',

    // wishlist endpoints
    wishlistGetlist: 'SmithCoreWishList_getLists',
    'wishlist-newlist': 'SmithCoreWishList_addList',
    'wishlist-deletelist': 'SmithCoreWishList_removeList',
    'wishlist-add': 'SmithCoreWishList_addHotel',
    'wishlist-remove': 'SmithCoreWishList_removeHotel',
    wishlistAllhotels: 'SmithCoreWishList_allHotels',

    // shop endpoints
    productPrices: 'SmithCoreShopBundle_productPricesForCurrency',
    productMembershipPrices: 'SmithCoreShopBundle_productMembershipPricesForCurrency',

    // map endpoints
    mapGetPropertyDetail: 'SmithCoreMapsBundle_getProperty',
    mapGetAvailablilty: 'SmithCoreMapsBundle_getAvailability',
    mapGetAllData: 'SmithCoreMapsBundle_getAllData',

    // Booking
    getBookingSummary: 'SmithCoreBookingBundle_bookingSummaryAPI',
    getBookingSummaryPi: 'SmithCoreBookingBundle_bookingSummaryByPaymentIntentAPI',
    confirmWithApplePay: 'SmithCoreBookingBundle_confirmWithApplePay',
    addVoucher: 'SmithCoreBookingBundle_addVoucherAPI',
    removeVoucher: 'SmithCoreBookingBundle_removeVoucherAPI',
    getCountryList: '/get-countries',
    getStatesList: '/get-states-list',
    addLoyalty: 'SmithCoreBookingBundle_addLoyaltyAPI',
    removeLoyalty: 'SmithCoreBookingBundle_removeLoyaltyAPI',
    getCountryShipping: 'SmithCoreBookingBundle_getShippingOptionsByCountryAPI',
    addShipping: 'SmithCoreBookingBundle_addShippingToBasketAPI',
    removeShipping: 'SmithCoreBookingBundle_RemoveShipping',
    amazonPaySaveBookingId: 'SmithCoreBookingBundle_SaveAmazonBookingId',
    removeAmazonPaySession: 'SmithCoreBookingBundle_PayWithCard',
    confirmOrder: 'SmithCoreBookingBundle_confirmAPI',
    confirmCollectionsOrder: 'CollectionsSmithCoreBookingBundle_confirmAPI',
    confirmPaymentBS2: 'SmithCoreBookingBundle_handlePaymentAPI',
    awardVote: 'SmithCoreProviderBundle_vote',
    getCountries: 'SmithCoreVoteBundle_countries',

    // Payment Intent
    createPaymentIntent: 'SmithCorePaymentBundle_createPaymentIntent',
    confirmPaymentIntent: 'SmithCorePaymentBundle_confirmPaymentIntent',
    paymentIntentStatus: 'SmithCorePaymentBundle_getPaymentIntentStatus',

    // Giftlist
    getGiftListContribute: 'family_giftlist_get',
    getGiftlists: 'smith_membershub_giftlists_data',
    createGiftlist: 'smith_membershub_giftlist_create',
    removeGiftlist: 'smith_membershub_giftlist_remove',
    getGiftlistDetail: 'smith_membershub_giftlist_content_api',
    editGiftlistDetail: 'smith_membershub_giftlist_update',
    confirmContributePayment: 'family_giftlist_contribute',

    // Bookings
    getBookingsDetails: 'smith_membershub_bookings_get',

    // Loyalty
    getLoyalty: 'smith_membershub_loyalty_dashboard_get_api',

    // Wishlist
    getWishlistDetail: 'smith_membershub_wishlist_content_get',
    getWishlists: 'smith_membershub_wishlists_get',
    createWishlist: 'smith_membershub_wishlists_create',
    removeWishlist: 'smith_membershub_wishlists_remove',
    editWishlist: 'smith_membershub_wishlists_edit',
    // Favourites
    addItemToFavourites: 'SmithCoreWishList_addProperty',
    removeItemFromFavourites: 'SmithCoreWishList_removeProperty',

    // Login
    checkLogin: 'FamilyAuthBundle_checkLogin',
    getToken: 'FamilyAuthBundle_authToken',
    signUp: 'SmithCoreCustomerBundle_signup',
    forgotPassword: 'FamilyAuthBundle_forgotPassword',
    socialLogin: 'FamilyAuthBundle_socialLogin',

    // Guestbook
    getGuestbooks: 'smith_membershub_guestbook_reviews_api_get',
    addGuestbookEntry: 'smith_membershub_guestbook_review_api_put',

    // Shopping Offers
    getShoppingOffers: 'smith_membershub_offers_fetch',

    // Hotel search
    getAllInclusiveDetails: 'hotel_what_is_included',

    dataCapture: '/form/subscription',
    validateVoucher: 'FamilyVoucherBundle_activate',

    // Gift List Contribute page
    giftListContribute: '',

    // Payment page - Members area
    confirmPayment: 'smith_membershub_payment_confirmation',

    // Cancelling bookings
    bookingCancellationData: 'smith_membershub_booking_cancel_modal',
    cancelLineItemsForBooking: 'smith_membershub_online_cancellation',

    editableBanner: 'FamilyContentBundle_editableBannerBySection'
};
