import React, { cloneElement, useState, useEffect } from 'react';
import PasswordHidden from '../../common/icons/PasswordHidden';
import PasswordShown from '../../common/icons/PasswordShown';
import Tick from '../icons/Tick';
import { checkCharactersLength, checkCharacterType, isANumber, isLowerCase, isUpperCase, isSpecialCharacter} from '../../utilities/passwordValidation';

interface IProps {
    children: any;
    showValidationTooltip: boolean
};

const PasswordInput: React.FC<IProps> = ({children, showValidationTooltip}) => {
    const [isInputTypePassword, setIsInputTypePassword] = useState(true);
    const [showValidation, setShowValidation] = useState(false);
    const [charsLengthStatus, setCharsLengthStatus] = useState('');
    const [lowerCaseCharStatus, setLowerCaseCharStatus] = useState('');
    const [upperCaseCharStatus, setUpperCaseCharStatus] = useState('');
    const [numberCharStatus, setNumberCharStatus] = useState('');
    const [specialCharStatus, setSpecialCharStatus] = useState('');

    const passwordValue = children.props.value;

    const renderPasswordIcon = () => {
        if (isInputTypePassword) {
            return <PasswordHidden />
        }
        return <PasswordShown />
    };

    const togglePasswordHide = () => {
        setIsInputTypePassword(!isInputTypePassword);
    };

    const setPasswordType = isInputTypePassword ? 'password' : 'text';

    const onFocus = () => {
        setShowValidation(true);
    };

    const onBlur = () => {
        setShowValidation(false);
    };

    useEffect(() => {
        const hasPasswordLength = checkCharactersLength(passwordValue, 8);
        const hasLowerCase = checkCharacterType(passwordValue, isLowerCase);
        const hasUpperCase = checkCharacterType(passwordValue, isUpperCase);
        const hasANumber = checkCharacterType(passwordValue, isANumber);
        const hasSpecialChar = checkCharacterType(passwordValue, isSpecialCharacter);

        if (!hasPasswordLength) {
            hasPasswordLength ? setCharsLengthStatus('valid') : setCharsLengthStatus('');
            hasLowerCase ? setLowerCaseCharStatus('valid') : setLowerCaseCharStatus('');
            hasUpperCase ? setUpperCaseCharStatus('valid') : setUpperCaseCharStatus('');
            hasANumber ? setNumberCharStatus('valid') : setNumberCharStatus('');
            hasSpecialChar ? setSpecialCharStatus('valid') : setSpecialCharStatus('');
        } else {
            hasPasswordLength ? setCharsLengthStatus('valid') : setCharsLengthStatus('error');
            hasLowerCase ? setLowerCaseCharStatus('valid') : setLowerCaseCharStatus('error');
            hasUpperCase ? setUpperCaseCharStatus('valid') : setUpperCaseCharStatus('error');
            hasANumber ? setNumberCharStatus('valid') : setNumberCharStatus('error');
            hasSpecialChar ? setSpecialCharStatus('valid') : setSpecialCharStatus('error');
        }
        
    }, [passwordValue])

    const passwordValidation = () => {
        return (<ul className="c-passwordValidation">
            <li className={`c-passwordValidation__item c-passwordValidation__item--${charsLengthStatus}`}>
                <Tick className={`c-passwordValidation__icon c-passwordValidation__icon--${charsLengthStatus}`} />
                At least eight characters
            </li>
            <li className={`c-passwordValidation__item c-passwordValidation__item--${specialCharStatus}`}>
                <Tick className={`c-passwordValidation__icon c-passwordValidation__icon--${specialCharStatus}`} />
                One special character
            </li>
            <li className={`c-passwordValidation__item c-passwordValidation__item--${upperCaseCharStatus}`}>
                <Tick className={`c-passwordValidation__icon c-passwordValidation__icon--${upperCaseCharStatus}`} />
                One capital letter
            </li>
            <li className={`c-passwordValidation__item c-passwordValidation__item--${lowerCaseCharStatus}`}>
                <Tick className={`c-passwordValidation__icon c-passwordValidation__icon--${lowerCaseCharStatus}`} />
                One lower case letter
            </li>
            <li className={`c-passwordValidation__item c-passwordValidation__item--${numberCharStatus}`}>
                <Tick className={`c-passwordValidation__icon c-passwordValidation__icon--${numberCharStatus}`} />
                One number
            </li>
        </ul>)
    }

    return (
        <div className="c-form__password"
                data-length={charsLengthStatus}
                data-lowerCase={lowerCaseCharStatus}
                data-upperCase={upperCaseCharStatus}
                data-number={numberCharStatus}
                data-specialCahr={specialCharStatus}
            >
            {cloneElement(children, { 
                type: setPasswordType,
                onFocus: onFocus,
                onBlur: onBlur,
            })}
            <div className="c-form__passwordIcon" onClick={() => togglePasswordHide()}> 
                {renderPasswordIcon()}
            </div>
            {showValidationTooltip && showValidation && passwordValidation()}
        </div>
    )
};

export default PasswordInput;
