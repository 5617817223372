import React from 'react';

const PasswordShown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <path fill="#A2A2A2" d="M9.597 16.247l-.282.413-.826-.564.282-.413c1.898-2.776 4.413-4.183 7.502-4.183 3.01 0 5.582 1.47 7.497 4.214l.286.41-.82.572-.286-.41C21.214 13.8 18.94 12.5 16.273 12.5c-2.75 0-4.96 1.237-6.676 3.747zm-.816.266l-.283-.413.825-.565.283.412c1.714 2.502 3.922 3.735 6.667 3.735 2.736 0 4.939-1.225 6.65-3.712l.284-.412.824.568-.284.411c-1.894 2.751-4.4 4.145-7.474 4.145-3.084 0-5.596-1.402-7.492-4.17zm7.492 3.866c-2.285 0-4.137-1.852-4.137-4.137 0-2.284 1.852-4.136 4.137-4.136 2.284 0 4.136 1.852 4.136 4.136 0 2.285-1.852 4.137-4.136 4.137zm0-1c1.732 0 3.136-1.404 3.136-3.137 0-1.732-1.404-3.136-3.136-3.136-1.732 0-3.137 1.404-3.137 3.136 0 1.733 1.405 3.137 3.137 3.137zm0-5.56c1.339 0 2.424 1.085 2.424 2.423 0 1.34-1.085 2.425-2.424 2.425-1.34 0-2.425-1.086-2.425-2.425 0-.338.07-.66.195-.952.168.211.425.346.715.346.502 0 .909-.407.909-.909 0-.289-.135-.546-.346-.713.292-.127.614-.196.952-.196z"/>
    </svg>
);

export default PasswordShown;

