
export const loadJS = (file, callback, attrs) => {
    let scriptTag;

    const scriptName = encodeURIComponent(file);
    const trackScript = (src, cb) => {
        if (cb && typeof cb === 'function') {
            loadJS.scripts[encodeURIComponent(src)] = {
                ready: false,
                cbQueue: [callback]
            };
        }
    };

    // load a single JS file
    if (typeof file === 'string' && !(loadJS.scripts && loadJS.scripts[scriptName])) {
        scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'text/javascript');
        scriptTag.setAttribute('async', 'true');
        scriptTag.setAttribute('src', file);

        if (typeof attrs === 'object') {
            const attrObj = Object.keys(attrs);

            attrObj.forEach(prop => scriptTag.setAttribute(prop, attrs[prop]));
        }

        trackScript(file, callback);

        // attach callback
        scriptTag.onload = () => {
            const script = loadJS.scripts[scriptName];
            const callbackQueue = script.cbQueue;

            script.ready = true;

            if (callbackQueue.length > 0) {
                callbackQueue.forEach(queuedCallback => queuedCallback());
            }
        };

        document.body.appendChild(scriptTag);
    } else if (loadJS.scripts && loadJS.scripts[scriptName]) {
        if (callback && typeof callback === 'function') {
            if (loadJS.scripts[scriptName].ready) {
                callback();
            } else {
                loadJS.scripts[scriptName].cbQueue.push(callback);
            }
        }
    }
};

loadJS.scripts = Object.create(null);

