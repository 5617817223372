import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { enableBatching } from 'redux-batched-actions';
import globalReducer from '../common/reducers';
import getFromDataLayer from '../utilities/getFromDataLayer';

const middlewares = [];
middlewares.push(thunk);

const env = getFromDataLayer(window.dataLayer, 'env');
const userData = getFromDataLayer(window.dataLayer, 'user');

if (env && env.envName !== 'prod') {
    const loggerMiddleware = createLogger();
    middlewares.push(loggerMiddleware);
}

const initialState = {
    user: {
        firstname: userData && userData.firstname || '',
        reference: userData && userData.reference || false
    },
    favourites: {
        favouriteLists: [],
        itemData: {},
        hasDataLoaded: false
    }
};

const globalStore = createStore(
    enableBatching(globalReducer),
    initialState,
    applyMiddleware(...middlewares)
);

export default globalStore;
