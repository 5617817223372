import getFromDataLayer from '../utilities/getFromDataLayer';
import * as CURRENCY_KEYS from '../common/constants/stripeCurrencyKeys';

export default (productionKey, localKey) => {
    const env = getFromDataLayer(window.dataLayer, 'env');

    return env && env.envName === 'prod' ? productionKey : localKey;
};

export const geStripeCurrencyKey = (currencyCode) => {
    let key = 'CURRENCY_KEY_';
    const env = getFromDataLayer(window.dataLayer, 'env');
    const usKeyCurrencies = ['usd', 'cad'];

    key = env && env.envName === 'prod' ? `${key}PROD_` : `${key}DEV_`;

    key = usKeyCurrencies.indexOf(currencyCode.toLowerCase()) !== -1 ? `${key}US` : `${key}ROW`;

    return CURRENCY_KEYS[key];
};
