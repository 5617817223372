import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/components/restyled/Icon';

const CounterNotification = ({
    items,
    toggleModal,
}) => {
    return (
        <span
            className="fw-btn ghost small monospaced with-no-children"
            data-counter={items.length}
            role="button"
            onClick={toggleModal}
            onKeyPress={toggleModal}
            tabIndex="0"
        >
            <div className="icon-wrapper">
                <Icon symbol="heart-medium" />
                {items.length > 0 &&
                    <span aria-hidden class="fw-badge info absolute-positioned has-content">
                        {items.length}
                    </span>
                }
            </div>
        </span>
    );
};

/**
 * propTypes
 *
 * @property {string} type to add icon type
 * @property {array} items the items to calculate the counter
 * @property {func} toggleModal function to toggle the modal
 *
 */

CounterNotification.propTypes = {
    iconType: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    toggleModal: PropTypes.func
};

CounterNotification.defaultProps = {
    iconType: '',
    items: [],
    toggleModal: () => {}
};

export default CounterNotification;
