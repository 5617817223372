export default (elements, observerCallback, options) => {
    const defaultOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
        trackVisibility: true,
        delay: 100
    };

    const observerOptions = options ? Object.assign({}, defaultOptions, options) : defaultOptions;

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elements.map(element => observer.observe(element));

    return observer;
};
