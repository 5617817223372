export const initializeAccordions = () => {
    const accordions: NodeListOf<HTMLElement> = document.querySelectorAll('.fw-accordion');

    accordions.forEach((accordion: HTMLElement) => {
        const title: HTMLElement | null = accordion.querySelector('.accordion-title');

        if (title) {
            title.addEventListener('click', () => {
                const isActive: boolean = accordion.classList.contains('expanded');
                accordions.forEach((item: HTMLElement) => item.classList.remove('expanded'));

                if (!isActive) {
                    accordion.classList.add('expanded');
                }
            });
        }
    });
};
