import setEnvKey from '../../utilities/getEnvKey';

export const STRIPEPAY = 'https://js.stripe.com/v2/';
export const ALLIES_POSTCODE_LOOKUP = '//ws.postcoder.com/pcw/PCWZ7-94PL8-XZGHH-6NYC4';
export const AMAZONPAY = setEnvKey('https://static-eu.payments-amazon.com/OffAmazonPayments/uk/lpa/js/Widgets.js', 'https://static-eu.payments-amazon.com/OffAmazonPayments/gbp/sandbox/lpa/js/Widgets.js');
export const facebookSDKUrl = '//connect.facebook.net/en_US/all.js';
export const facebookAppId = '372230786215255';
export const bugsnagApiKey = '8336a1fbdf8031944ffd9fcdf3301e8e';
export const reCaptchaKey = '6LcVcEsUAAAAAOwPwb-1GJ1IKonu8nX1QUTxx2Wr';
export const reCaptchaUrl = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaOnloadCallback&render=explicit';
export const GOOGLE_LOGIN_URL = 'https://apis.google.com/js/platform.js';
export const GOOGLE_CLIENT_ID = '1051715977484-nhlo0jfb7tedkb7v1ujmob40se165k7u';
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibXJhbmRtcnNzbWl0aCIsImEiOiJjandqM25yNXYwMXRpNGFvNnY2bDBjM2s4In0.yZ58FkFpfawOssylK2FUmA';
export const MAPBOX_RTL_PLUGIN = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js';
export const GOOGLEMAP_DIRECTION_BASE = 'https://www.google.com/maps/dir//';

const PRODUCTION_LIVEAGENT_CONFIG = {
    domain: 'https://mrandmrssmith.my.salesforce.com',
    siteUrl: 'https://mrandmrssmith.my.site.com/SmithChat',
    orgId: '00D20000000Cnnl',
    embeddedName: 'Smith_Embedded_Web_Chat',
    liveAgent: {
        baseLiveAgentContentURL: 'https://c.la3-c1-cdg.salesforceliveagent.com/content',
        deploymentId: '5722000000002cb',
        buttonId: '5736M000000Ccz2',
        baseLiveAgentURL: 'https://d.la3-c1-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I6M000000fxTOUAY_18aad2ef386',
        isOfflineSupportEnabled: false,
    },
    scriptSrc: 'https://mrandmrssmith.my.salesforce.com/embeddedservice/5.0/esw.min.js',
};

const TESTING_LIVEAGENT_CONFIG = {
    domain: 'https://mrandmrssmith--chatsand.sandbox.my.salesforce.com',
    siteUrl: 'https://mrandmrssmith--chatsand.sandbox.my.site.com/embeddedchattest',
    orgId: '00DMU0000008gX3',
    embeddedName: 'TestEmbeddedChat',
    liveAgent: {
        baseLiveAgentContentURL: 'https://c.la1-c1cs-cdg.salesforceliveagent.com/content',
        deploymentId: '572MU0000004C9h',
        buttonId: '573MU0000004C9w',
        baseLiveAgentURL: 'https://d.la1-c1cs-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IMU0000008OJ82AM_18a50851740',
        isOfflineSupportEnabled: false,
    },
    scriptSrc: 'https://mrandmrssmith--chatsand.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
};

export const LIVEAGENT_CONFIG = process.env.NODE_ENV === 'production' ? PRODUCTION_LIVEAGENT_CONFIG : TESTING_LIVEAGENT_CONFIG;
export const LIVEAGENT_SCRIPT = LIVEAGENT_CONFIG.scriptSrc;
