/* eslint-disable */

import $ from 'jquery';
import { getCurrentBreakpoint, pixelRatio } from '../utilities/device';
import { responsiveCodeMap, responsiveCodeWithMobileMap, breakpoints } from '../common/constants/breakpoints';
import responsiveImages from '../common/services/resizeImages';
import getImages from '../common/services/getImages';


/**
 * Slidetoggle hidden content
 * @param  {string}   itemSelector    class or id of selector
 * @param  {int}      limit           item limit to slice, default 3
 * @param  {int}      speed           speed of toggle, default 500
 * @param  {String}   easing          animation easing, default swing
 * @param  {object}   triggerWording  wording to use, .more and .less
 * @param  {Function} callback        callback function
 */
export const slideToggleContent = function(itemSelector, limit = 3, speed = 500, easing = 'swing', triggerWording, callback) {
    return this.each( function () {
        const no = limit;
        const s = speed;
        const es = easing;
        const $trigger = $(this);
        const $items = $(itemSelector);
        let $slice;
        const callbackFunc = callback;

        if ($items.length <= no && limit !== 0) {
            // not enough elements... button not needed
            $trigger.remove();
        } else {
            $trigger.on('click', function(event) {
                $slice = limit === 0 ? $items : $items.slice(no);
                if (parseInt($slice.css('opacity'), 10) === 0) {
                    // expand...
                    $slice.slideToggle(s, function() {
                        //then fade in...
                        $(this).animate({
                            opacity: 1
                        }, s);

                        if (typeof callbackFunc === 'function') {
                            callbackFunc();
                        }
                    });
                    $(this).html(triggerWording && triggerWording.less ? triggerWording.less : 'show less');
                } else {
                    // fade out...
                    $slice.animate({
                        opacity: 0
                    }, s, function() {
                        const callback = callbackFunc;
                        // then collapse...
                        $(this).slideToggle(s, () => {
                            if (typeof callbackFunc === 'function') {
                                callback();
                            }
                        });
                    });

                    $(this).html(triggerWording && triggerWording.more ? triggerWording.more : 'show more');
                }
            }).trigger('click');
        }
    });
};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};

const responsiveImgQueue = [];

/**
 * Responsive image solution
 * @param  {string}  attr_name  name of attr
 * @param  {Boolean} expect_src default false
 */
export const loadImgOnDemand = function (attr_name, expect_src = false, carousel = false, smithcodeuris = '') {
    return this.each(
        function () {
            const $this = $(this);
            const uri = $this.attr(attr_name) || smithcodeuris;

                const checkAndLoad = function () {
                    if ($this.offset().top <= $(window).scrollTop() + (window.innerHeight * 1.5) && $this.is(':visible') || carousel === true) {
                        $(window).off('scroll.odimg', checkAndLoad);
                        if ($this.data('requested') !== true) {
                           $this.data('requested', true);
                            if (uri.indexOf('JSON:') >= 0) {
                                const jsonObj = JSON.parse(uri.substr(5));
                                $.each(jsonObj, function (index, val) {
                                    $.data($this[0], index, val);
                                });
                                const responsiveCodeLength = Object.keys(jsonObj).length;
                                let currentBreakpoint = getCurrentBreakpoint();
                                if (getCurrentBreakpoint() === breakpoints.xSmall && responsiveCodeLength === 5) {
                                    currentBreakpoint = breakpoints.small;
                                }
                                responsiveImages($this, currentBreakpoint, true, uri.indexOf('JSON:') >= 0);
                                responsiveImgQueue.push($this);
                            } else if (($this.attr(attr_name)).split(',').length === 1) {
                                getImages('images', {
                                    image_code: uri,
                                    size_multiplier: pixelRatio
                                }, function (data) {
                                    $this.attr('src', data.url_ssl);

                                    if ($this.attr('data-alt')) {
                                        $this.attr('alt', $this.attr('data-alt'));
                                    }
                                }, (!!expect_src ? expect_src : false));

                            } else if (($this.attr(attr_name)).split(',').length > 1) {
                                const responsiveCodes = ($this.attr(attr_name)).split(',');
                                $.each(responsiveCodes, function (index, val) {
                                    const breakpoint = getBreakPointFromIndex(index, responsiveCodes.length, responsiveCodeMap, responsiveCodeWithMobileMap);
                                    $.data($this[0], breakpoint, val);

                                    if ($this.attr('data-alt')) {
                                        $this.attr('alt', $this.attr('data-alt'));
                                    }
                                });
                                responsiveImgQueue.push($this);
                                let currentBreakpoint = getCurrentBreakpoint();
                                if (getCurrentBreakpoint() === breakpoints.xSmall && responsiveCodes.length === 5) {
                                    currentBreakpoint = breakpoints.small;
                                }
                                responsiveImages($this, currentBreakpoint, true);
                            }
                        }
                    }
                };
            checkAndLoad();
            $(window).on( 'scroll.odimg', checkAndLoad);
        }
    );
};

const getBreakPointFromIndex = (index, length, responsiveCodeMap, responsiveCodeWithMobileMap) => {
    const map = length === 6 ? responsiveCodeWithMobileMap : responsiveCodeMap;

    return map[index];
}

const imageResize = debounce(() => {
    $.each(responsiveImgQueue, function () {
        const uri = this.attr('data-smithcode') || this.attr('data-smithsrc');
        let responsiveCodeLength = 5;
        let currentBreakpoint = getCurrentBreakpoint();

        if (uri.indexOf('JSON:') >= 0) {
            const jsonObj = JSON.parse(uri.substr(5));
            responsiveCodeLength = Object.keys(jsonObj).length;
        } else {
            responsiveCodeLength = uri.split(',').length;
        }

        if (getCurrentBreakpoint() === breakpoints.xSmall && responsiveCodeLength === 5) {
            currentBreakpoint = breakpoints.small;
        }


        responsiveImages(this, currentBreakpoint, true, uri.indexOf('JSON:') >= 0);
    });
}, 250);

const isHomePage = window.location.pathname === '/';

if (!isHomePage) {
    $(window).on('resize.responsiveimgs', imageResize);
}
