import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withFormValidation from '../../hocs/withFormValidation';
import * as validationService from '../../../area/booking/services/validationService';
import GetStarted from '../GetStarted';
import CreateFavouriteList from './CreateFavouriteList';
import ViewFavouriteLists from './ViewFavouriteLists';

const wishListStages = {
    create: 'CREATE',
    start: 'START',
    view: 'VIEW'
};
const CreateFavouriteWithValidation = withFormValidation(CreateFavouriteList, validationService);
const setStage = items => (items.length === 0 ? wishListStages.start : wishListStages.view);
const membersWishlistURL = '/members/wishlists';

export default class FavouriteModalStages extends Component {
    /**
     * propTypes
     * @property {array} favouriteLists an array of objects FavouriteModel - which is set in the globalReduxStore
     * @property {function} createFavouriteList the action to create a new favouriteList
     * @property {object} user the user details
     * @property {object} itemData the data for the item to add or remove
     * @property {function} addItemToList the action to add a new item to the list
     * @property {function} removeItemFromList the action to remove an item from the list
     */

    static get propTypes() {
        return {
            favouriteLists: PropTypes.arrayOf(PropTypes.object).isRequired,
            createFavouriteList: PropTypes.func.isRequired,
            user: PropTypes.objectOf(PropTypes.any).isRequired,
            addItemToList: PropTypes.func.isRequired,
            removeItemFromList: PropTypes.func.isRequired,
            itemData: PropTypes.objectOf(PropTypes.string).isRequired
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            wishListStage: setStage(props.favouriteLists) // can have 3 values: start/create/view
        };
        this.setStageToCreate = this.setStageToCreate.bind(this);
        this.setStageToView = this.setStageToView.bind(this);
    }

    setStageToCreate() {
        this.setState({ wishListStage: wishListStages.create });
    }

    setStageToView(isStageView) {
        if (isStageView) {
            this.setState({ wishListStage: wishListStages.view });
        }
    }

    render() {
        const { wishListStage } = this.state;
        const {
            favouriteLists,
            user,
            itemData,
            createFavouriteList,
            addItemToList,
            removeItemFromList,
            closeNotification
        } = this.props;

        if (wishListStage === wishListStages.start) {
            return (
                <GetStarted
                    title="Welcome to your favourites"
                    copy={`Hello ${user.firstname}, this is where you can save all your favourite hotels and villas, curate that dream trip or plan future itineraries.`}
                    buttonText="Get Started"
                    getStarted={this.setStageToCreate}
                />
            );
        }
        if (wishListStage === wishListStages.create) {
            return (
                <CreateFavouriteWithValidation
                    title="Create a list of favourites"
                    cancelCreateList={closeNotification}
                    initialURL={membersWishlistURL}
                    createList={createFavouriteList}
                    setStageToView={this.setStageToView}
                />
            );
        }
        if (wishListStage === wishListStages.view) {
            return (
                <ViewFavouriteLists
                    userReference={user.reference}
                    title="My favourite lists"
                    favouriteLists={favouriteLists}
                    openCreateList={this.setStageToCreate}
                    membersWishlistURL={membersWishlistURL}
                    itemData={itemData}
                    addItemToList={addItemToList}
                    removeItemFromList={removeItemFromList}
                />
            );
        }
        return null;
    }
}
