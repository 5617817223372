import endpoints from '../constants/endpoints';
import objectToParams from '../../../utilities/objectToParams';
import router from '../../../app/backend_router';

export default {
    /**
     * Fetches page data
     * @return {promise}
     */
    getPreferencesPage() {
        return fetch(router.generate(endpoints.getPreferencesPage, { _format: 'json' }), { credentials: 'include' })
            .then(response => response.json())
            .then(payload => payload);
    },

    savePreferences(preferences) {
        const queryParameters = objectToParams(preferences);

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-Requested-With': 'XMLHttpRequest'
        });

        const fetchOptions = {
            credentials: 'include',
            headers,
            method: 'PATCH',
            body: queryParameters
        };

        return fetch(router.generate(endpoints.savePreferences, { _format: 'json' }), fetchOptions)
            .then(response => response.json())
            .then(payload => payload);
    }
};

