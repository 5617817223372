import React, { Component, Fragment } from 'react';
import ModalElement from './ModalElement';
import { IPropsModal } from './types';

interface IState {
    show?: boolean;
    modalDismiss?: boolean
}

export default class Modal extends Component<IPropsModal, IState> {
    state = {
        show: this.props.show,
        modalDismiss: typeof this.props.allowOverlayDismiss !== 'undefined' ? this.props.allowOverlayDismiss : true
    };

    UNSAFE_componentWillReceiveProps(nextProps: IPropsModal) {
        this.setState({ show: nextProps.show });
    }

    shouldComponentUpdate(nextProps: IPropsModal, nextState: IState) {
        const { modalDismiss, show } = this.state;
        return ((show !== nextState.show && modalDismiss) || (modalDismiss !== nextState.modalDismiss && show));
    }

    close = () => {
        const { callback } = this.props;
        this.setState({ show: false }, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    dismiss = () => {
        this.setState({ modalDismiss: false })
    }

    render() {
        const { show, modalDismiss } = this.state;
        const {
            children,
            wrapperClass,
            modalType,
            closeAndReload,
            closeIconModifier
        } = this.props;

        const clonedChildren = React.cloneElement(children, {dismiss: this.dismiss});

        return (
            <Fragment>
                {show
                    && (
                        <ModalElement
                            allowOverlayDismiss={modalDismiss}
                            close={this.close}
                            wrapperClass={wrapperClass}
                            modalType={modalType}
                            closeAndReload={closeAndReload}
                            closeIconModifier={closeIconModifier}
                        >
                            {clonedChildren}
                        </ModalElement>
                    )
                }
            </Fragment>
        );
    }
}
