import { breakpointWidths, breakpoints, mediaQueries } from '../common/constants/breakpoints';

const getScreenWidth = () => window.screen.width;
const getScreenHeight = () => window.screen.height;


const deviceDetails = {
    isTouchscreen: () => 'ontouchstart' in window && 'ontouchend' in window
};

/**
 * Return screen object with current
 * screen height and width
 * @type {Object} screen - width, height
 */
export const screenInfo = {
    width: getScreenWidth(),
    height: getScreenHeight()
};


/**
 * Returns if device is touchscreen
 * @return {bool}
 */
export const isTouchscreen = deviceDetails.isTouchscreen();


/**
 * @param  check if screen height or width
 * exceeds given breakpoint range
 * @return {bool}
 */
export const screenExceeds = range => screenInfo.width > breakpointWidths[range].maxWidth
    || screenInfo.height > breakpointWidths[range].maxWidth;


/**
 * Get current breakpoint
 * @return {string} breakpoint
 */
export const getCurrentBreakpoint = () => {
    let windowWidth;
    if (window.matchMedia(mediaQueries.xSmall).matches) {
        windowWidth = breakpoints.xSmall;
    } else if (window.matchMedia(mediaQueries.small).matches) {
        windowWidth = breakpoints.small;
    } else if (window.matchMedia(mediaQueries.medium).matches) {
        windowWidth = breakpoints.medium;
    } else if (window.matchMedia(mediaQueries.large).matches) {
        windowWidth = breakpoints.large;
    } else if (window.matchMedia(mediaQueries.xLarge).matches) {
        windowWidth = breakpoints.xLarge;
    } else if (window.matchMedia(mediaQueries.xxLarge).matches) {
        windowWidth = breakpoints.xxLarge;
    }

    return windowWidth;
};

/**
 * Get screen / device pixel ratio
 * @return {int}
 */
export const pixelRatio = window.devicePixelRatio || undefined;

export const isNumberKey = keyEntryEvent => keyEntryEvent >= 48 && keyEntryEvent <= 57;

export const keys = {
    backspace: 8,
    delete: 46
};
