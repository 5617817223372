import React from 'react';
import PropTypes from 'prop-types';
import withOutsideClickHandler from '../hocs/withOutsideClickHandler';

const UiNotification = ({
    type,
    arrowPosition,
    arrowPositionOption,
    customClassName,
    hasCloseButton,
    closeNotification,
    children
}) => {
    const classForArrowPosition = arrowPositionOption ? `c-notificationElement__notification--${arrowPositionOption}` : '';
    const classForNotification = type ? `c-notificationElement--${type}` : '';
    const customClass = customClassName ? `c-notificationElement--${customClassName}` : '';

    return (
        <div className={`c-notificationElement__notification ${classForNotification} ${classForArrowPosition} ${customClass}`} >
            <div style={arrowPosition} className="c-notificationElement__arrow" />
            <div className="c-notificationElement__body">
                <div className="c-notificationElement__content">{children}</div>
                { hasCloseButton &&
                    <button className="c-notificationElement__close" type="button" onClick={closeNotification} />
                }
            </div>
        </div>
    );
};
    /**
     * propTypes
     *
     * @property {string} type the type of notification to render
     * @property {object} children the html to be rendered in the notification
     * @property {boolean} hasCloseButton to render or not the close Button
     * @property {function} closeNotification function to close the notification
     *
     */

UiNotification.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['', 'info', 'error', 'modal']),
    hasCloseButton: PropTypes.bool,
    closeNotification: PropTypes.func
};

UiNotification.defaultProps = {
    type: '',
    hasCloseButton: false,
    closeNotification: () => {}
};

export const UiNotificationWithBodyClickhandler = withOutsideClickHandler(UiNotification);

export default UiNotification;



