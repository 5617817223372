import 'whatwg-fetch';
require('intersection-observer');
/* eslint-disable import/first */
import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import globalStore from './reduxGlobalStore';
import { LIVEAGENT_SCRIPT } from '../common/constants/thirdPartyJs';
import { slideToggleContent, loadImgOnDemand } from '../utilities/functions';
import { currentUrl } from '../utilities/url';
import { isTouchscreen } from '../utilities/device';
import { loadJS } from '../utilities/loadJs';
import attachLoginModal from '../utilities/attachLoginModal';
import createObserver from '../utilities/lazyload';
import FavouritesIconContainer from '../common/components/favourites/FavouritesIcon';
import getFromDataLayer from '../utilities/getFromDataLayer';
import initializeMenuUtils from '../common/components/initializeMenuUtils';
import { initializeAccordions } from '../common/components/restyled/utilities/initializeAccordions';
import { initializeLinksDropdown } from '../common/components/restyled/utilities/initializeLinksDropdown';

require('expose-loader?renderFavouritesButton!../common/helpers/favourites/renderFavouritesButton');

const doesPageMatch = (pattern) => pattern.test(currentUrl);

const membersHubPattern = /\/(members)$/;
const sideStoryPattern = /\/(experiences(\/?)|experience-search)/;
const thingsToDo = /things-to-do/;
const shopBasketStep2Pattern = /\/(sho{1,3}p)\/|sho{1,3}p$|step(1|2|Two)|basket$/;
const stepPattern = /step(1|2|Two)/;
const shopPattern = /\/(sho{1,3}p)\/|sho{1,3}p$/;

const isHomePage = window.location.pathname === '/';
const isMembersHub = doesPageMatch(membersHubPattern);
const isSideStoryPage = doesPageMatch(sideStoryPattern) && !doesPageMatch(thingsToDo);
const isShopBasketOrStep = doesPageMatch(shopBasketStep2Pattern);
const isStepPage = doesPageMatch(stepPattern);
const isShopPage = doesPageMatch(shopPattern);

export default () => {
    if (isTouchscreen) {
        document.body.classList.add('touchscreen');
    }

    initializeMenuUtils();
    initializeAccordions();
    initializeLinksDropdown();

    //* this section cannot be deferred to onload */

    $.fn.extend({ slideToggleContent, loadImgOnDemand });
    loadJS(LIVEAGENT_SCRIPT, () => {
        const chatRootNode = document.getElementById('live-chat');
        if (chatRootNode) {
            import(/* webpackChunkName: "livechat" */ '../common/components/LiveChat').then(({ default: LiveChat }) => {
                render(<LiveChat />, document.getElementById('live-chat'));
            });
        }
    });

    //* End section no deffer */

    window.addEventListener('load', () => {
        let observer = null;

        if (isHomePage || isSideStoryPage || isShopPage || isMembersHub) {
            const pictureElements = Array.from(document.querySelectorAll('.j-lazyPicture'));
            if (!('HTMLPictureElement' in window)) {
                import(/* webpackChunkName: "picturefill" */ '../vendor/pictureFill').then(
                    ({ picFill1, pictureFill }) => {
                        picFill1(window);
                        pictureFill(window, document);
                        createObserver(pictureElements);
                    }
                );
            } else {
                createObserver(pictureElements);
            }
        }

        $('img[data-smithcode]').loadImgOnDemand('data-smithcode');
        $('img[data-smithsrc]').loadImgOnDemand('data-smithsrc', true);

        if (!isShopBasketOrStep) {
            const autosuggestContainer = document.getElementById('autosuggest-container');
            if (autosuggestContainer) {
                autosuggestContainer.getElementsByClassName('loader')[0].classList.add('smithloader');
            }

            require.ensure(
                [],
                (require) => {
                    const siteClickEvents = require('../common/components/siteClickEvents');
                    siteClickEvents.default();
                },
                'siteClickEvents'
            );

            import(/* webpackChunkName: "bootstrapRestyledSearchBar" */ './bootstrapRestyledSearchBar').then((restyledSearch) => {
                restyledSearch.default();
            });
        }

        if (document.getElementById('basket_count')) {
            require.ensure(
                [],
                (require) => {
                    const basketCount = require('../utilities/basketCount');

                    basketCount.default();
                },
                'basketCount'
            );
        }

        if (!isStepPage) {
            const favsIconRootNode = document.getElementById('favourites-icon');
            const loginRoot = document.createElement('div');
            loginRoot.setAttribute('id', 'loginRoot');
            document.getElementById('main-section').appendChild(loginRoot);

            if (favsIconRootNode) {
                render(
                    <Provider store={globalStore}>
                        <FavouritesIconContainer iconType="favourites" />
                    </Provider>,
                    favsIconRootNode
                );
            }
        }

        if (!isStepPage) {
            attachLoginModal(document.querySelectorAll('.j-initialiseLoginModal'));
        }

        if (process.env.NODE_ENV === 'production') {
            import(/* webpackChunkName: "bugsnaglogger" */ './logger').then(({ default: logger }) => {
                logger();
            });
        }

        const signupNewsletterModal = getFromDataLayer(window.dataLayer, 'newsletter_modal');

        if (signupNewsletterModal) {
            const modalFormRoot = document.createElement('div');
            modalFormRoot.setAttribute('id', 'newsletterModalRoot');
            const mainSectionDiv = document.getElementById('main-section');

            if (mainSectionDiv) {
                mainSectionDiv.appendChild(modalFormRoot);

                const ModalModule = import(/* webpackChunkName: "modal" */ '../common/components/modal/Modal');
                const DataCaptureFormContainerModule = import(
                    /* webpackChunkName: "dataCaptureFormContainer" */ '../components/dataCaptureForm/DataCaptureFormContainer'
                    );

                Promise.all([ModalModule, DataCaptureFormContainerModule]).then((response) => {
                    const [{ default: Modal }, { default: DataCaptureFormContainer }] = response;

                    const newMemberFormTrigger = document.querySelector('a[href="#newMember"]');

                    if (newMemberFormTrigger) {
                        newMemberFormTrigger.addEventListener('click', (event) => {
                            event.preventDefault();
                            render(
                                <Modal show={true} wrapperClass="c-modal__captureFormsWrapper">
                                    <DataCaptureFormContainer
                                        formEndpoint="/form/subscription/new-members"
                                        smithNewsletter={true}
                                    />
                                </Modal>,
                                document.getElementById('newsletterModalRoot')
                            );
                        });
                    }
                });
            }
        }
    });
};
