import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const InputTypes = ({
    type,
    name,
    label,
    ariaLabel,
    placeholder,
    value,
    isRequired,
    isDisabled,
    error,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    pattern,
    maxlength,
    className,
    modifier,
    pageSpecificClass,
    validator,
    errorMessage,
    keydown,
    click,
    inputRef,
    checked,
    min,
    inputMode,
    showErrorMessage,
    autocomplete,
    render,
    layout
}) => {
    const inputProps = {
        ref: inputRef,
        type,
        name,
        value,
        onBlur,
        onChange,
        onFocus,
        onKeyPress,
        required: isRequired,
        placeholder,
        'aria-label': ariaLabel,
        pattern,
        maxLength: maxlength,
        className: `c-form__input c-form__input--${type}`,
        id: name,
        'data-validator': validator,
        'data-error': errorMessage,
        onKeyDown: keydown,
        onClick: click,
        checked,
        min,
        inputMode,
        disabled: isDisabled,
    };

    if (autocomplete) {
        inputProps.autoComplete = autocomplete;
    }
    const fieldLayout = `c-form__field c-form__field--${layout}`;
    const pageClass = pageSpecificClass !== '' ? `${pageSpecificClass}` : '';
    const fieldClasses = error && error.length > 0 ? `${fieldLayout} ${pageClass} c-form__field--hasError` : `${fieldLayout} ${pageClass}`;

    const labelTypes = `c-form__label c-form__label--${type}`;
    const labelClasses = isRequired ? `${labelTypes} c-form__label--isRequired` : `${labelTypes}`;

    const modifierClass = modifier !== '' ? `c-form__field--${modifier}` : '';
    const setHTMLLabel = { __html: label };

    return (
        <div className={`${fieldClasses} ${modifierClass}`}>
            {layout === 'inverted' || layout === 'floating' ?
                <Fragment>
                    <input {...inputProps} />
                    <label
                        className={labelClasses}
                        htmlFor={name}
                        dangerouslySetInnerHTML={setHTMLLabel}
                    >
                    </label>
                    {render && <span className="c-form__inputHint">{render(value)}</span>}
                </Fragment>
                :
                <Fragment>
                    <label
                        className={labelClasses}
                        htmlFor={name}
                        dangerouslySetInnerHTML={setHTMLLabel}
                    >
                    </label>
                    <input {...inputProps} />
                    {render && <span className="c-form__inputHint">{render(value)}</span>}
                </Fragment>
            }
            {error && showErrorMessage && <div className="c-form__error">{error}</div>}
        </div>
    );
};

const disableEnterOnInput = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
        event.preventDefault();
        return false;
    }
    return true;
};

InputTypes.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string,
    layout: PropTypes.oneOf(['default', 'inverted', 'floating']),
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    pageSpecificClass: PropTypes.string,
    pattern: PropTypes.string,
    maxlength: PropTypes.number,
    className: PropTypes.string,
    modifier: PropTypes.string,
    validator: PropTypes.string,
    errorMessage: PropTypes.string,
    keydown: PropTypes.func,
    inputRef: PropTypes.func,
    checked: PropTypes.bool,
    min: PropTypes.string,
    inputMode: PropTypes.string,
    showErrorMessage: PropTypes.bool,
    autocomplete: PropTypes.string,
    render: PropTypes.func,
    click: PropTypes.func
};

InputTypes.defaultProps = {
    type: 'text',
    isRequired: false,
    isDisabled: false,
    placeholder: '',
    ariaLabel: '',
    value: '',
    error: '',
    onBlur: () => {},
    onFocus: () => {},
    onKeyPress: () => {},
    pageSpecificClass: '',
    validator: '',
    errorMessage: 'Please check',
    className: '',
    modifier: '',
    keydown: disableEnterOnInput,
    inputRef: () => {},
    checked: false,
    inputMode: null,
    showErrorMessage: true,
    autocomplete: '',
    render: null,
    click: () => {},
    layout: 'floating'
};

export default InputTypes;
