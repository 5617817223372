/**
 * Wrapper around document.cookie
 * @param {string} cookie value to set
 */
const setDocumentCookie = (cookie) => {
    document.cookie = cookie;
};

/**
 * Gets the document hasCookie
 * @Returns {String} cookie
 */
const getDocumentCookie = () => document.cookie;

/**
 * Returns a boolean indicating if cookie is present
 * @param {string} cookieName to check for
 * @returns {Boolean} if cookie is present
 */
export const hasCookie = (cookieName) => {
    const cookieList = getDocumentCookie().split(';');
    const cookieRegex = new RegExp(cookieName);
    let isPresent = false;
    // loop through cookies to check if name exists
    for (let i = 0; i < cookieList.length; i += 1) {
        if (cookieRegex.test(cookieList[i])) {
            isPresent = true;
            break;
        }
    }
    return isPresent;
};


export const getCookie = (cname) => {
    var name = cname + "=",
        ca = document.cookie.split(';'),
        i,
        c,
        ca_length = ca.length;
    for (i = 0; i < ca_length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
/**

/**
 * Create new cookie
 * @param  {string} name name for new cookie
 * @constant {number} duration duration of the cookie
 * @return cookie
 */
export const createCookie = (cookieName, cookieValue = true, cookieDuration = 365) => {
    // TODO: refactor dependency on Date
    const date = new Date();
    date.setTime(date.getTime() + (cookieDuration * 24 * 60 * 60 * 1000));
    const cookieExpires = date.toUTCString();
    const cookie = `${cookieName}=${cookieValue}; expires=${cookieExpires}; path=/`;

    setDocumentCookie(cookie);
};
