import getImages from './getImages';
import { pixelRatio } from '../../utilities/device';


const ratio = pixelRatio > 2 ? 2 : pixelRatio;

let currentBreakpoint;

export default ($imgElm, breakpoint, force, JSONsmithCode = false) => {
    if (JSONsmithCode) {
        $imgElm.attr('src', $imgElm.data(breakpoint));
    } else if (currentBreakpoint !== breakpoint || force) {
        currentBreakpoint = breakpoint;
        getImages('images', {
            image_code: $imgElm.data(breakpoint),
            size_multiplier: ratio
        },
        (data) => {
            $imgElm.attr('src', data.url_ssl);
        });
    }
};
