import React, { Component, Fragment } from 'react';
import InputTypes from '../../../common/components/InputTypes';
import api from '../../../common/services/api';

export default class ForgotPasswordForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            formFields: {
                email: ''
            },
            isProcessing: false,
            message: '',
            token: '',
            closeModalBox: false
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.submitForgot = this.submitForgot.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        api.getToken().then((token) => {
            this.setState({
                token: token.csrf_token
            });
        });
    }

    onChangeHandler(event) {
        const field = event.target.name;
        const { formFields } = this.state;

        this.setState({
            message: '',
            formFields: Object.assign({}, formFields, {
                [field]: event.target.value
            })
        });
    }

    submitForgot(event) {
        event.preventDefault();

        const { validateForm, closeModal } = this.props;
        const { formFields, token } = this.state;

        const form = event.target.form;

        validateForm(form).then((errors) => {
            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                if (errors[curr] !== '') {
                    acc += 1;
                }
                return acc;
            }, 0);

            if (errors) {
                this.setState({
                    message: 'Please enter a valid Email address'
                });
            }

            if (numberOfErrors === 0) {
                this.setState({
                    isProcessing: true,
                    message: ''
                });
                api.forgotPassword(formFields, token).then((response) => {
                    if (response.status === false) {
                        this.setState({
                            isProcessing: false,
                            message: response.message
                        });
                    } else {
                        this.setState({
                            isProcessing: false,
                            message: response.message,
                            closeModalBox: true
                        });
                    }
                });
            }
        });
    }

    showMessage() {
        const { message } = this.state;

        if (message !== '') {
            return <p className="c-authentication__error">{ message }</p>;
        }
    }

    closeModal() {
        const { closeModal } = this.props;
        closeModal();
    }

    renderForgotPasswordForm() {
        const { formFields: { email }, isProcessing } = this.state;
        const { onBlurValidation, formErrors } = this.props;
        return (
            <form className="c-forgot__form clearfix">
                <InputTypes
                    name="email"
                    value={email}
                    isRequired={true}
                    placeHolder="Email"
                    label="Email"
                    pattern="^\w+([\.\+!%-]?\w+)*@\w+([\.\-]?\w+)*(\.\w{2,})+$"
                    onChange={this.onChangeHandler}
                    onBlur={onBlurValidation}
                    error={formErrors.email}
                />
                { this.showMessage() }
                <button className="c-authentication__cta" disabled={isProcessing} onClick={this.submitForgot}>Submit</button>
            </form>
        )
    }

    renderFooter() {
        const { changeFormAction } = this.props;
        return (
            <footer className="c-authentication__footer">
                <p className="c-authentication__footerText">Already a member?</p>
                <a className="c-authentication__footerAction" onClick={changeFormAction}>Sign in</a>
            </footer>
        )
    }

    renderForgotPassword() {
        return (
            <Fragment>
                <section className="c-authentication__content c-authentication__content--forgot">
                    <h2 className="c-authentication__title">Forgot your password?</h2>
                    <p className="c-authentication__headerMessage">Enter your email address and we'll send you a link to change it</p>
                    {this.renderForgotPasswordForm()}
                </section>
                {this.renderFooter()}
            </Fragment>
        )
    }

    renderThanks() {
        const { message } = this.state;
        return (
            <section className="c-authentication__content">
                <h2 className="c-authentication__title">Thanks!</h2>
                <p className="c-authentication__headerMessage">{ message }</p>
                <button className="c-authentication__cta" onClick={this.closeModal}>Close</button>
            </section>
        )
    }

    render() {
        const { closeModalBox } = this.state;

        return (
            <Fragment>
                { !closeModalBox ? this.renderForgotPassword() : this.renderThanks() }
            </Fragment>
        );
    }
}
