import getKeyValuesFromObject from '../../../utilities/getKeyValuesFromObject';
import { FavouriteModel } from './FavouriteModel';

export default items => items.reduce((acc, item) => {
    if (item.hotels.length !== 0) {
        const propertiesIds = item.hotels.reduce(getKeyValuesFromObject('property'), []);
        acc.push(new FavouriteModel(item.list_id, item.title, item.urlname, propertiesIds));
    } else {
        acc.push(new FavouriteModel(item.list_id, item.title, item.urlname));
    }
    return acc;
}, []);
