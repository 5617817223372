export const initializeLinksDropdown = () => {
    const dropdowns: NodeListOf<HTMLElement> = document.querySelectorAll('.fw-dropdown .dropdown-section');

    dropdowns.forEach((dropdownElement) => {
        const title = dropdownElement.querySelector('.dropdown-title');

        title?.addEventListener('click', () => {
            const isActive = dropdownElement.classList.contains('expanded');

            dropdowns.forEach((otherDropdownElement) => {
                otherDropdownElement.classList.remove('expanded');
            });

            if (!isActive) {
                dropdownElement.classList.add('expanded');
            }
        });

        dropdownElement.addEventListener('mouseleave', () => {
            dropdownElement.classList.remove('expanded');
        });
    });
};
