export const breakpointWidths = {
    xSmall: {
        minWidth: undefined,
        maxWidth: 550
    },
    small: {
        minWidth: 481,
        maxWidth: 767
    },
    medium: {
        minWidth: 768,
        maxWidth: 1023
    },
    large: {
        minWidth: 1024,
        maxWidth: 1439
    },
    xlarge: {
        minWidth: 1440,
        maxWidth: 1919
    },
    xxlarge: {
        minWidth: 1920,
        maxWidth: undefined
    }
};

export const breakpoints = {
    xSmall: 'XSMALL',
    small: 'SMALL',
    medium: 'MEDIUM',
    large: 'LARGE',
    xLarge: 'XLARGE',
    xxLarge: 'XXLARGE'
};

export const responsiveCodeMap = {
    0: breakpoints.small,
    1: breakpoints.medium,
    2: breakpoints.large,
    3: breakpoints.xLarge,
    4: breakpoints.xxLarge
};

export const responsiveCodeWithMobileMap = {
    0: breakpoints.xSmall,
    1: breakpoints.small,
    2: breakpoints.medium,
    3: breakpoints.large,
    4: breakpoints.xLarge,
    5: breakpoints.xxLarge
};

export const mediaQueries = {
    xSmall: `(max-width: ${breakpointWidths.xSmall.maxWidth}px)`,
    small: `(max-width: ${breakpointWidths.small.maxWidth}px)`,
    medium: `(max-width: ${breakpointWidths.medium.maxWidth}px)`,
    large: `(max-width: ${breakpointWidths.large.maxWidth}px)`,
    xLarge: `(max-width: ${breakpointWidths.xlarge.maxWidth}px)`,
    xxLarge: `(min-width: ${breakpointWidths.xxlarge.minWidth}px)`
};
