import classNames from "classnames";
import React, { ButtonHTMLAttributes, ReactElement, ReactNode } from "react";
import { IconProps } from '../Icon';

export type IconsHelper =
    | {
          children?: never;
          icons: {
              center: ReactElement<IconProps>;
          };
      }
    | {
          children?: ReactNode;
          icons?:
              | {
                    left: ReactElement<IconProps>;
                    right?: ReactElement<IconProps>;
                }
              | {
                    left?: ReactElement<IconProps>;
                    right: ReactElement<IconProps>;
                }
              | {
                    center?: ReactElement<IconProps>;
                };
      };

export type ButtonWidth = 'default' | 'fixed' | 'full';

export type ButtonStyle = {
    displayStyle?: 'primary' | 'secondary' | 'ghost' | 'unstyled' | 'link';
    width?: ButtonWidth;
};

type XSmallSizedButton = {
    size: 'xsmall';
    children?: ReactNode;
    icons?: {
        center: ReactElement<IconProps>;
    };
    loading?: never;
};

type MediumAndLargeSizedButton = {
    size?: 'text' | 'small' | 'medium' | 'large';
    loading?: boolean;
} & IconsHelper;

export type ButtonContent = XSmallSizedButton | MediumAndLargeSizedButton;

export type ButtonBaseProps = ButtonStyle & ButtonContent;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & ButtonBaseProps;

export const wrapIcon = (icon: ReactNode, position: 'left' | 'right' | 'center') => {
    let large = false;

    try {
        large = React.isValidElement(icon) && icon.props.spritemap === 'large';
    } catch (error) {
        console.error(error);

        return null;
    }

    return (
        <div
            className={classNames('icon-wrapper', {
                'large-icon-wrapper': large,
                'icon-wrapper-left': position === 'left',
                'icon-wrapper-right': position === 'right',
            })}
        >
            {icon}
        </div>
    );
};

export const getButtonClassnames = ({
    displayStyle = 'primary',
    className,
    size = 'medium',
    width,
    icons,
    disabled,
    children,
    loading,
}: ButtonBaseProps & {
    className?: string;
    disabled?: boolean;
}) => {
    const unstyled = displayStyle === 'unstyled';

    return classNames(
        'btn-restyled', 
        !unstyled && {
            'fw-btn': !unstyled,
            [displayStyle]: !unstyled,
            [size]: true,
            'full-width': width === 'full',
            'fixed-width': width === 'fixed',
            'monospaced': icons && 'center' in icons,
            'disabled': disabled,
            'with-left-icon': icons && 'left' in icons,
            'with-right-icon': icons && 'right' in icons,
            'with-no-children': !children,
            'loading': loading,
        },
        className
    );
};