import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import InputTypes from '../../../common/components/InputTypes';
import Button from '../../../common/components/Button';
import { MARKETING_OPT_IN_LABEL } from '../../../common/constants/form';
import api from '../../membershub/services/myAccountPreferenceServices';
import preferenceFormDataBuilder from '../../membershub/pages/myAccount/formbuilders/preferencesFormDataBuilder';

class MarketingPreference extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optIn: false,
            isLoading: false
        };

        this.onCheckHandler = this.onCheckHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const { dismiss } = this.props;
        dismiss();
    }

    onCheckHandler(event) {
        this.setState({
            optIn: event.target.checked
        });
    }

    onSubmit() {
        const { optIn } = this.state;
        const { returnLink } = this.props;
        const optInValue = { marketing: optIn ? 'Y' : 'N'};
        
        this.setState({ isLoading: true });

        api.savePreferences(optInValue).then(() => {
            if (returnLink && returnLink !== encodeURIComponent(window.location.href)) {
                return Promise.resolve(window.location.assign(decodeURIComponent(returnLink)));
            }
            return Promise.resolve(window.location.reload());
        });
    }

    renderOptInSection() {
        const { userProfile: { firstName, email, imgSrc } } = this.props;
        const title = `Hi, ${firstName}`;

        return (
            <section className="c-authentication__content">
                { imgSrc && <img src={imgSrc} className="c-authentication__image" alt="google" />}
                <h2 className="c-authentication__title c-authentication__title--optin">{title}<br/>{"you're nearly done"}</h2>
                <p className="c-authentication__email">{email}</p>
                <p className="c-authentication__note c-authentication__note--email">We’ll send your booking confirmations here</p>
                {this.renderOptInForm()}
            </section>
        );
    }
    renderOptInForm() {
        const { optIn, isLoading } = this.state;
        const buttonClasses = isLoading ? "c-authentication__cta c-authentication__cta--optin c-authentication__cta--loading" : "c-authentication__cta c-authentication__cta--optin";
        return (
            <form>
                <div className="c-signUpForm__checkbox">
                    <InputTypes
                        layout="inverted"
                        name="optIn"
                        value={optIn}
                        checked={optIn}
                        type="checkbox"
                        placeHolder={MARKETING_OPT_IN_LABEL}
                        label={MARKETING_OPT_IN_LABEL}
                        onChange={this.onCheckHandler}
                    />
                    <p className="c-authentication__note c-authentication__note--checkbox">We may contact you by email, text message or post. You can update this in your settings at any time.</p>
                </div>

                <Button
                    text="Accept"
                    onClick={this.onSubmit}
                    classes={buttonClasses}
                />
            </form>
        )
    }


    renderFooter() {
        return (
            <footer className="c-authentication__footer">
                <p className="c-authentication__footerText">Please read our
                    {' '}
                    <a href={`${window.location.origin}/booking-terms/`} target="_blank" rel="noopener noreferrer" className="c-authentication__footerAction">terms and conditions</a>
                    {' '}
                    &amp;
                    {' '}
                    <a href={`${window.location.origin}/legal/`} target="_blank" rel="noopener noreferrer" className="c-authentication__footerAction">privacy policy</a>
                </p>
            </footer>
        )
    }

    render() {
        return (
            <Fragment>
                {this.renderOptInSection()}
                {this.renderFooter()}
            </Fragment>
        );
    }
}


export default MarketingPreference;
