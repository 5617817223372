import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import equals from 'ramda/src/equals';
import { getItemData, clearItemData } from '../../actions/favourites';
import Button from '../Button';
import Heart from '../../icons/Heart';

export class FavouritesButton extends Component {
    /**
     * @property {string} itemId the property id
     * @property {string} itemType the property type
     * @property {string} showButtonText the text showing on the button
     * @property {function} actions the actions
     * @property {bool} isUserLoggedIn flag to see if the user is logged in
     * @property {object} favourites the object for favourites which is saved in the store
     */

    static isFavourite = (favouriteLists, itemId) => {
        let isInFavourites = false;
        for (const list of favouriteLists) { //eslint-disable-line
            if (list.propertiesIds.indexOf(itemId) > -1) {
                isInFavourites = true;
                break;
            }
        }
        return isInFavourites;
    }

    static get propTypes() {
        return {
            itemId: PropTypes.string.isRequired,
            itemType: PropTypes.string.isRequired,
            showButtonText: PropTypes.string.isRequired,
            actions: PropTypes.objectOf(PropTypes.func).isRequired,
            isUserLoggedIn: PropTypes.bool.isRequired,
            favourites:
            PropTypes.objectOf(PropTypes.oneOfType([
                PropTypes.bool, PropTypes.objectOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)
            ]))
        };
    }

    static defaultProps = {
        favourites: {}
    }

    constructor(props) {
        super(props);

        this.state = {
            isFavouriteProperty: false
        };
        this.openFavouritesPanel = this.openFavouritesPanel.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { favourites: { favouriteLists }, itemId } = this.props;

        if (!equals(favouriteLists, prevProps.favourites.favouriteLists)) {
            const hasFav = FavouritesButton.isFavourite(favouriteLists, itemId);
            this.setState({
                isFavouriteProperty: hasFav
            });
        }
    }

    openFavouritesPanel() {
        const { itemId, itemType, actions: { updateStateWithItemData, clearItem }, isUserLoggedIn } = this.props;
        const isItemIdSet = localStorage.getItem('itemData');

        const itemData = {
            itemId,
            itemType
        };


        if (!isUserLoggedIn) {
            if (isItemIdSet) {
                clearItem().then(() => updateStateWithItemData(itemData));
            } else {
                localStorage.setItem('itemData', JSON.stringify(itemData));
                updateStateWithItemData(itemData);
            }
        } else {
            updateStateWithItemData(itemData);
        }
    }

    render() {
        const { isFavouriteProperty } = this.state;
        const { showButtonText} = this.props;

        let iconWidth = '28px';
        let buttonClass= 'c-favouritesButton';
        let buttonText = 'ADD TO FAVOURITES';
        let fill = '#ffffff';

        if (isFavouriteProperty) {
            buttonClass = 'c-favouritesButton c-favouritesButton--added';
            buttonText = 'ADDED TO FAVOURITES';
            fill = '#D53439';
        }
        
        if (showButtonText !== 'true') {
            buttonText =  '';
            iconWidth = '38px';
        }
        
        return (
            <Button
                classes={buttonClass}
                onClick={this.openFavouritesPanel}
                type="button"
            >
                <Heart className="c-icon c-icon--withShadow" fill={fill} width={iconWidth} height={iconWidth} />
                {buttonText}
            </Button>
        );
    }
}

export default connect(
    state => ({
        isUserLoggedIn: state.user.reference !== false,
        favourites: state.favourites,
    }),
    dispatch => ({
        actions: {
            updateStateWithItemData: bindActionCreators(getItemData, dispatch),
            clearItem: bindActionCreators(clearItemData, dispatch)
        }
    })
)(FavouritesButton);
