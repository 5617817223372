import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import withFormValidation from '../../../common/hocs/withFormValidation';
import * as validationService from '../../booking/services/validationService';
import ForgotPasswordForm from './ForgotPasswordForm';
import MarketingPreferences from './MarketingPreference';

const LoginFormWithValidation = withFormValidation(
    LoginForm,
    validationService
);
const SignUpFormWithValidation = withFormValidation(
    SignUpForm,
    validationService
);
const ForgotPasswordFormWithValidation = withFormValidation(
    ForgotPasswordForm,
    validationService
);

const onSuccessActionOptions = {
    reload: 'reload',
    redirect: 'redirect',
    dispatch: 'dispatch'
};

const bobs = [
    { title: 'extra', content: 'Smith extras <br /> on arrival' },
    { title: 'exclusive', content: 'Exclusive <br /> Offers' },
    { title: 'bestPrice', content: 'Best-price <br /> guarantee' }
];

export default class LoginContainer extends Component {
    static get propTypes() {
        return {
            allowSignup: PropTypes.bool,
            onSuccessAction: PropTypes.string,
            showHeader: PropTypes.bool,
            allowSocialAuth: PropTypes.bool
        };
    }

    static defaultProps = {
        allowSignup: true,
        onSuccessAction: '',
        showHeader: true,
        allowSocialAuth: true
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            showSignUp: false,
            showForgot: false,
            onSuccessAction: props.onSuccessAction,
            showOptinModal: false,
            userProfile: {}
        };

        this.changeForms = this.changeForms.bind(this);
        this.changeLoginForms = this.changeLoginForms.bind(this);
        this.showForgot = this.showForgot.bind(this);
        this.hideForgot = this.hideForgot.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
    }

    componentDidMount() {
        const { type } = this.props;

        if (type && type === 'signup') {
            this.setState({
                showSignUp: true
            });
        }
    }

    onSuccess(signUpType, userProfile) {
        const { onSuccessAction, returnLink, dispatch } = this.props;

        if (onSuccessAction === onSuccessActionOptions.redirect) {
            if (signUpType === 'social') {
                this.setState({
                    showOptinModal: true,
                    showSignUp: false,
                    userProfile
                });
            } else {
                return Promise.resolve(window.location.assign(returnLink));
            }
        }
        if (onSuccessAction === onSuccessActionOptions.dispatch) {
            return dispatch();
        }

        if (signUpType === 'social') {
            this.setState({
                showOptinModal: true,
                showSignUp: false,
                userProfile
            });
        } else {
            return Promise.resolve(window.location.reload());
        }       
    }

    changeForms() {
        this.setState({
            showSignUp: true
        });
    }

    changeLoginForms() {
        this.setState({
            showSignUp: false
        });
    }

    showForgot() {
        this.setState({
            showForgot: true
        });
    }

    hideForgot() {
        this.setState({
            showForgot: false
        });
    }

    renderSignup() {
        const { closeModal, returnLink, trigger, headerMessage, allowSocialAuth } = this.props;
        return (
            <div>
                <SignUpFormWithValidation
                    changeFormAction={this.changeLoginForms}
                    headerMessage={headerMessage}
                    closeModal={closeModal}
                    returnLink={returnLink}
                    trigger={trigger}
                    onSuccess={this.onSuccess}
                    allowSocialAuth={allowSocialAuth}
                />
            </div>
        );
    }

    renderForgot() {
        const { closeModal } = this.props;
        return (
            <ForgotPasswordFormWithValidation
                changeFormAction={this.hideForgot}
                closeModal={closeModal}
            />
        );
    }

    renderLogin() {
        const {
            closeModal,
            returnLink,
            trigger,
            headerMessage,
            allowSignup,
            allowSocialAuth
        } = this.props;
        return (
            <LoginFormWithValidation
                headerMessage={headerMessage}
                showForgot={this.showForgot}
                changeFormAction={this.changeForms}
                closeModal={closeModal}
                returnLink={returnLink}
                trigger={trigger}
                onSuccess={this.onSuccess}
                allowSignup={allowSignup}
                allowSocialAuth={allowSocialAuth}
            />
        );
    }

    renderAuthentication() {
        const { showSignUp, showForgot, showOptinModal, userProfile } = this.state;
        const { dismiss, returnLink } = this.props;
        if (showSignUp && !showForgot && !showOptinModal) {
            return this.renderSignup();
        }
        if (!showSignUp && !showForgot && !showOptinModal) {
            return this.renderLogin();
        }
        if (showForgot && !showSignUp && !showOptinModal) {
            return this.renderForgot();
        }
        if (showOptinModal && !showSignUp && !showForgot) {
            return (
                <MarketingPreferences
                    userProfile={userProfile}
                    dismiss={dismiss}
                    returnLink={returnLink}
                />
            );
        }
    }

    render() {
        const { showHeader } = this.props;
        return (
            <article className="c-authentication clearfix">
                {showHeader && (
                    <header className="c-authentication__header">
                        <img
                            className="c-authentication__logo"
                            src="/bundles/familysystem/images/Smith_travel_club_logo_light.svg"
                            alt=""
                        />
                    </header>
                )}
                {this.renderAuthentication()}
            </article>
        );
    }
}
