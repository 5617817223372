import React from 'react';

interface IProps {
    className?: string;
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
}

const Tick = ({ className, width, height, viewBox = '0 0 45 41', fill }: IProps) => (
    <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className={className}
        width={width}
        height={height}
    >
        <path
            d="M19.7461 40.545L0.974092 26.3C0.55147 25.9794 0.273533 25.504 0.201425 24.9784C0.165721 24.7181 0.181624 24.4534 0.248226 24.1993C0.314828 23.9453 0.430824 23.7068 0.589592 23.4975C0.74836 23.2882 0.94679 23.1123 1.17355 22.9797C1.40032 22.8472 1.65097 22.7605 1.91121 22.7248C2.43678 22.6527 2.96947 22.7924 3.39209 23.113L18.7761 34.789L41.1531 1.22401C41.2981 1.00396 41.4852 0.814745 41.7036 0.667241C41.922 0.519736 42.1674 0.41685 42.4257 0.364495C42.684 0.312139 42.9501 0.311347 43.2087 0.362164C43.4673 0.412981 43.7133 0.514405 43.9325 0.660607C44.1518 0.806808 44.34 0.994905 44.4863 1.21408C44.6327 1.43326 44.7343 1.6792 44.7852 1.93777C44.8362 2.19633 44.8356 2.46242 44.7834 2.72074C44.7312 2.97906 44.6285 3.22452 44.4811 3.44301L19.7461 40.545Z"
            fill={fill}
        />
    </svg>
);

export default Tick;
