import { combineReducers } from 'redux';
import * as actionTypes from '../actions/globalActionTypes';
import { FavouriteModel } from '../helpers/favourites/FavouriteModel';

const addNewItem = (state, payload) => state.map((favouriteModel) => {
    if (favouriteModel.id === payload.listId) {
        return Object.assign(
            Object.create(FavouriteModel.prototype),
            favouriteModel,
            { propertiesIds: [...favouriteModel.propertiesIds, payload.propertyId] }
        );
    }
    return favouriteModel;
});

const deleteFavouriteList = (state, listId) => state.filter(favouriteModel => favouriteModel.id !== listId);

const removeItem = (state, payload) => state.map((favouriteModel) => {
    if (favouriteModel.id === payload.listId) {
        const itemsAfterRemove = favouriteModel.propertiesIds.filter(item => item !== payload.propertyId);
        return Object.assign(
            Object.create(FavouriteModel.prototype),
            favouriteModel,
            { propertiesIds: itemsAfterRemove }
        );
    }
    return favouriteModel;
});

const editFavouriteList = (state, listData) => state.map((favouriteModel) => {
    if (favouriteModel.id === listData.id) {
        return Object.assign(Object.create(FavouriteModel.prototype), favouriteModel, { title: listData.title });
    }
    return favouriteModel;
});

export const favouriteLists = (state = [], action) => {
    switch (action.type) {
        case actionTypes.INIT_FAVOURITES_LISTS:
            return [...action.payload];
        case actionTypes.ADD_FAVOURITE_LIST:
            return [...state, action.payload];
        case actionTypes.DELETE_FAVOURITE_LIST:
            return deleteFavouriteList(state, action.listId);
        case actionTypes.EDIT_FAVOURITE_LIST:
            return editFavouriteList(state, action.listData);
        case actionTypes.ADD_ITEM_TO_FAVOURITE_LIST:
            return addNewItem(state, action.payload);
        case actionTypes.REMOVE_ITEM_FROM_FAVOURITE_LIST:
            return removeItem(state, action.payload);
        default:
            return state;
    }
};

export const itemData = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_ITEM_DATA:
            return Object.assign({}, state, action.payload);
        case actionTypes.CLEAR_ITEM_DATA:
            return Object.create({});
        default:
            return state;
    }
};

export const hasDataLoaded = (state = false, action) => {
    switch (action.type) {
        case actionTypes.FETCHED_RESOLVED:
            return action.isDataFetched;
        default:
            return state;
    }
};

export default combineReducers({
    favouriteLists,
    itemData,
    hasDataLoaded
});
