import objectToParams from './objectToParams';

export default (formFields, endpoint) => {
    const { postalname, ...address } = formFields.userAddress;
    const userAddressQueryString = objectToParams(address);
    const { userAddress, ...restOfProperies } = formFields;
    const queryString = objectToParams(restOfProperies);

    const postForm = `${queryString}&${userAddressQueryString}`;

    const headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
    });

    const fetchOptions = {
        credentials: 'include',
        method: 'POST',
        headers,
        body: postForm
    };

    return fetch(endpoint, fetchOptions)
        .then(response => response.json())
        .then(payload => payload);
};
