import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../restyled/Icon';
import Button from '../Button';

const FavouriteList = ({
    listTitle,
    listURL,
    items,
    itemType,
    buttonAction,
    buttonMode
}) => {
    const buttonActionText = buttonMode === 'add' ? `Add ${itemType}` : `Remove ${itemType}`;
    return (
        <li className="flex-row-top-justify c-favourites__item">
            <a href={listURL} className="c-favourites__itemName" data-items={items.length}>{listTitle}</a>
            {buttonMode === 'view'
                && <a className="c-favourites__link" href={listURL}>
                    <Icon className="credit-info-icon" symbol="arrow-right" />
                </a>
            }
            {(buttonMode === 'add' || buttonMode === 'remove')
                && (
                    <Button
                        type="button"
                        onClick={buttonAction}
                        text={buttonActionText}
                        classes={`c-ui-button--action c-ui-button--${buttonMode}`}
                    />
                )
            }
        </li>
    );
};


/**
 * propTypes
 * @property {string} listTitle the title for the List of Favourites
 * @property {string} listURL the url for the List of Favourites
 * @property {function} buttonAction the function to run when we click on the button
 * @property {number} items the number of properties in the list of favourites
 * @property {string} itemType the type of the property
 * @property {string} buttonMode the type of action
 */

FavouriteList.propTypes = {
    listTitle: PropTypes.string.isRequired,
    listURL: PropTypes.string.isRequired,
    buttonMode: PropTypes.oneOf(['view', 'add', 'remove']).isRequired,
    itemType: PropTypes.oneOf(['hotel', 'villa']),
    buttonAction: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.string)
};

FavouriteList.defaultProps = {
    items: [],
    itemType: 'hotel',
    buttonAction: () => {},
};

export default FavouriteList;
