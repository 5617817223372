let urlParams = {};

/**
 * set history state
 * @type {Object}
 */
export const historyState = {
    marvin: 'history state'
};

const loc = {
    getLocationSearch: () => window.location.search,
    getLocationHref: () => window.location.href,
    getLocationHash: () => window.location.hash,
    getLocationPathname: () => window.location.pathname,
    replaceState: url => window.history.replaceState(historyState, '', url),
    assign: url => window.location.assign(url)
};

export const currentUrl = loc.getLocationPathname();

/**
 * Check if search params entered
 */
const hasSearchParameters = () => !!loc.getLocationSearch();

const hasReplaceStateSupport = () => !!window.history && !!window.history.replaceState;

/**
 * Replace state of history
 * @param  {string} url
 */
export const replaceState = (url) => {
    if (hasReplaceStateSupport()) {
        loc.replaceState(url);
    } else {
        loc.assign(url);
    }
};

/**
 * Get all url params and put into object
 * @return {object} url params
 */
const getAllUrlParamsToObject = () => {
    if (hasSearchParameters()) {
        const params = {};
        let tmp;

        const parameters = (decodeURIComponent(loc.getLocationSearch())).slice(1).split('&');

        for (let i = 0; i < parameters.length; i += 1) {
            tmp = parameters[i].split('=');
            params[tmp[0]] = tmp[1];
        }
        urlParams = params;
    } else {
        urlParams = urlParams || {};
    }
};


/**
 * Fetch url params by key
 * @param  {string} key
 * @return {string} parameter
 */
export const fetchParamsByKey = (key) => {
    let parameter;
    getAllUrlParamsToObject();

    if (hasSearchParameters()) {
        if (key && typeof key === 'string') {
            parameter = urlParams[key];
        } else {
            parameter = urlParams;
        }
    } else {
        parameter = key ? undefined : urlParams;
    }
    return parameter;
};


/**
 * Push params to url
 * @param  {object/string} key
 * @param  {object/string} val   value of key
 * @return replace state
 */
export const pushParams = (key, val) => {
    const prm = [];

    getAllUrlParamsToObject();

    const prms = urlParams;

    // Check for object
    if (typeof key === 'object' && !Array.isArray(key)) {
        const paramObj = Object.keys(key);
        paramObj.forEach(i => prm.push(`${encodeURIComponent(i)}=${encodeURIComponent(key[i])}`));
    } else if (typeof key === 'string' && typeof val === 'string') {
        prms[key] = val;
        const paramString = Object.keys(prms);
        paramString.forEach(i => prm.push(`${encodeURIComponent(i)}=${encodeURIComponent(prms[i])}`));
    }

    replaceState(`${loc.getLocationPathname()}?${Array.isArray(prm) ? prm.join('&') : prm}${loc.getLocationHash()}`);
};


/**
 * Delete params from url
 * @param  {string} key
 */
export const deleteParams = (key) => {
    getAllUrlParamsToObject();

    if (urlParams[key]) {
        delete urlParams[key];
        if (Object.keys(urlParams).length === 0) {
            const url = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.hash}`;
            replaceState(url);
        } else {
            pushParams(urlParams);
        }
    }
};
