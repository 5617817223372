import React, { Component } from 'react';

import { IPropsModalElement } from './types';
import Cross from '../../icons/Cross';


export default class ModalElement extends Component<IPropsModalElement> {
    constructor(props: IPropsModalElement) {
        super(props);

        this.onClick= this.onClick.bind(this);
    }

    private modal = React.createRef<HTMLElement>();

    public static defaultProps = {
        allowOverlayDismiss: true
    };

    componentDidMount() {
        const { allowOverlayDismiss } = this.props;
        this.modal.current.scrollTop = 0;
        document.body.classList.add('hiddenOverflow');
        if (allowOverlayDismiss) {
            document.body.addEventListener('click', this.bodyClickHandler, true);
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('hiddenOverflow');
        document.body.removeEventListener('click', this.bodyClickHandler);
    }

    bodyClickHandler = (event: Event) => {
        const { close, closeAndReload } = this.props;
        if (closeAndReload && (event.target as HTMLBodyElement).className === 'c-modal') {
            window.location.reload();
        } else if ((event.target as HTMLBodyElement).className === 'c-modal') {
            event.stopPropagation();
            close();
        }
    }

    onClick() {
        const { closeAndReload, close } = this.props;
        
        if (closeAndReload) {
            window.location.reload();
        } else {
            event.stopPropagation();
            close();
        }
    }

    render() {
        const {
            allowOverlayDismiss,
            close,
            children,
            wrapperClass,
            modalType,
            closeIconModifier
        } = this.props;

        const wrapperClassName = wrapperClass ? `c-modal__contentWrapper ${wrapperClass}` : 'c-modal__contentWrapper';
        const iconClasses = closeIconModifier ? `c-modal__crossIcon c-modal__crossIcon--${closeIconModifier}` : 'c-modal__crossIcon';

        return (
            <section id="modelContainer" ref={this.modal} data-modal={modalType}>
                <div className="c-modal">
                    <div className={wrapperClassName}>
                        {allowOverlayDismiss && <button type="button" className="c-modal__closeButton" onClick={this.onClick}>
                            <Cross className={iconClasses}/>
                        </button>}
                        {React.cloneElement(children, { closeModal: close })}
                    </div>
                </div>
            </section>
        );
    }
}
