import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class SelectBox extends PureComponent {
    render() {

        const { name, options, onChange, showErrorMessage, valueIndex, value, placeholder, error, selectContainerClass, selectClass, isRequired, inputRef, label, showLabel, autocomplete } = this.props;

        const dropDownOptions = options.map(option => (
                <option key={option[0]} value={option[valueIndex]}>{option[1]}</option>
        ));

        const renderOptions = placeholder === 'false' ? dropDownOptions :
            [<option key={'placeholder'} value={''}>{placeholder}</option>, ...dropDownOptions];

        const classForSelect = `${selectContainerClass} ${error ? 'c-form__field--hasError' : ''}`;

        const selectBoxProps = {
            ref: inputRef,
            value,
            name,
            'aria-label': label,
            onChange,
            required: isRequired
        };

        if (autocomplete) {
            selectBoxProps.autoComplete = autocomplete;
        }

        return (
            <div className={`c-form__field ${classForSelect}`}>
                <label className={'c-form__label c-form__label--select ' + (isRequired ? 'c-form__label--isRequired' : '') + (!showLabel ? ' c-form__label--isHidden' : '')}>{label}</label>
                <div className="c-form__selectBox">
                    <select {...selectBoxProps} className={`c-form__select ${selectClass}`}>
                        { renderOptions }
                    </select>
                </div>
                { error && showErrorMessage && <div className="c-form__error">{error}</div> }
            </div>
        );
    }
}


SelectBox.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    onChange: PropTypes.func,
    valueIndex: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    inputRef: PropTypes.func,
    showErrorMessage: PropTypes.bool,
    showLabel: PropTypes.bool,
    selectContainerClass: PropTypes.string,
    selectClass: PropTypes.string,
    preselect: PropTypes.string
};

SelectBox.defaultProps = {
    valueIndex: 1,
    preselect: '',
    placeholder: 'select',
    value: '',
    isRequired: false,
    inputRef: () => {},
    onChange: () => {},
    showErrorMessage: false,
    selectContainerClass: '',
    selectClass: '',
    showLabel: false
};
