import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies
import api from './api';
import { PLACEHOLDER_IMAGE_SRC } from '../constants/global';

const cache = {};
const count = {
    requests: 0,
    successes: 0,
    empty: 0,
    failures: 0
};

export default (type = 'images', options, callback, skipApi = false) => {
    const defaultImg = { url_ssl: PLACEHOLDER_IMAGE_SRC };
    let requestKey;
    let img;

    try {
        requestKey = options.image_code;
        if (cache[requestKey]) {
            if (typeof callback === 'function') {
                callback(cache[requestKey]);
            }
            return cache[requestKey];
        }
        if (cache[requestKey] === false) {
            if (typeof callback === 'function') {
                callback(defaultImg);
            }
            return defaultImg;
        }

        if (skipApi) {
            const imageUri = options.image_code;
            const imageData = { url_ssl: imageUri };
            img = new Image();

            $(img).on('load', () => {
                cache[requestKey] = imageData;
                count.successes += 1;

                if (typeof callback === 'function') {
                    callback(imageData);
                }
                return imageUri;
            }).on('error', () => {
                count.failures += 1;
                cache[requestKey] = defaultImg;

                if (typeof callback === 'function') {
                    callback(imageData);
                }
                return false;
            }).attr('src', imageUri);
        } else {
            api.getImages(options)
                .then((data) => {
                    let apiData = data;

                    count.requests += 1;

                    if (apiData) {
                        if (!apiData.url_ssl) {
                            apiData = defaultImg;
                        } else {
                            img = new Image();

                            $(img).on('load', () => {
                                cache[requestKey] = apiData;
                                count.successes += 1;

                                if (typeof callback === 'function') {
                                    callback(apiData);
                                }
                                return apiData.url_ssl;
                            }).on('error', () => {
                                count.failures += 1;
                                cache[requestKey] = defaultImg;

                                if (typeof callback === 'function') {
                                    callback(apiData);
                                }
                                return false;
                            }).attr('src', apiData.url_ssl);
                        }
                    } else {
                        count.empty += 1;
                        apiData = defaultImg;
                    }
                    if (typeof callback === 'function') {
                        callback(apiData);
                    }
                }).catch(() => {
                    count.requests += 1;
                    count.failures += 1;
                    cache[requestKey] = defaultImg;
                });
        }
    } catch (err) {
        if (typeof callback === 'function') {
            callback(defaultImg);
        }
    }
};
