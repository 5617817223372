import React from 'react';
import PropTypes from 'prop-types';
import Button from '../components/restyled/Button';
import Icon from './restyled/Icon';

const GetStarted = ({
    title,
    copy,
    getStarted
}) => (
    <div className="c-favourites" data-stage="start">
        <h2 className="c-favourites__title">{title}</h2>
        <p className="c-favourites__copy">{copy}</p>
        <Button
            width="full"
            onClick={getStarted}
            text="get started"
            icons={{
                right: <Icon symbol="arrow-right" />,
            }}
        >
            Get started
        </Button>
    </div>
);

/**
 * propTypes
 * @property {string} title the title of the component
 * @property {string} copy the content of the component
 * @property {function} getStarted the on click function on the button
 */

GetStarted.propTypes = {
    title: PropTypes.string,
    copy: PropTypes.string,
    getStarted: PropTypes.func
};

GetStarted.defaultProps = {
    title: '',
    copy: '',
    getStarted: () => {}
};

export default GetStarted;
