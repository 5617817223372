import React from 'react';

interface IProps {
    className?: string;
    width?: string;
    height?: string;
    viewBox?: string;
    fill?: string;
}

const Heart = ({ className, width, height, viewBox = '0 0 42 42', fill }: IProps) => (
    <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        className={className}
        width={width}
        height={height}
    >
        <path
            fill={fill}
            d="M21 13.11c-.054-.082-.109-.162-.165-.241C19.592 11.116 17.865 10 15.61 10c-1.866 0-3.576.77-4.806 2.163C9.64 13.483 9 15.243 9 17.118c0 2.04.793 3.927 2.469 5.917 1.232 1.463 2.5 2.62 5.943 5.55 1.35 1.148 1.973 1.683 2.786 2.393l.029.026c.216.189.49.29.773.29.282 0 .557-.101.773-.29l.03-.026c.811-.71 1.43-1.24 2.785-2.393 3.443-2.93 4.711-4.087 5.943-5.55C32.207 21.045 33 19.157 33 17.118c0-1.875-.639-3.636-1.803-4.955C29.967 10.769 28.257 10 26.39 10c-2.255 0-3.983 1.116-5.226 2.869-.057.08-.111.16-.165.24z"
        />
    </svg>
);

export default Heart;
