/**
 * Makes an action creator
 * Given a type and optional argument names,
 * it returns a function that when called with values creats and action object
 * @param {String} type action type
 * @param {...string} argNames names of additional properties to add
 * @return {function} actioncreator
 */
export default (type, ...argNames) => (...args) => {
    const action = { type };

    argNames.forEach((arg, index) => {
        action[argNames[index]] = args[index];
    });

    return action;
};
