import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import InputTypes from '../../../common/components/InputTypes';
import { SelectBox } from '../../../common/components/SelectBox';
import api from '../../../common/services/api';
import GoogleLogin from './GoogleLogin';
import FacebookLogin from './FacebookLogin';
import getFromDataLayer from '../../../utilities/getFromDataLayer';
import { createCookie } from '../../../utilities/cookie';
import { LOGIN_CHANNEL_SMITH } from '../../../common/constants/global';
import { MARKETING_OPT_IN_TEXT, MARKETING_OPT_IN_LABEL } from '../../../common/constants/form';
import { LoginModes } from '../constants/login';
import PasswordInput from '../../../common/components/PasswordInput';

const userData = getFromDataLayer(window.dataLayer, 'user');
const cpOptInMessage = getFromDataLayer(window.dataLayer, 'optInMessage');

let userCountry = 'GB';
if (userData) {
    userCountry = userData.sessionCountry === 'UK' ? 'GB' : userData.sessionCountry;
}

export default class SignUpForm extends Component {
    /**
     * propTypes
     *
     * @property {bool} allowSocialAuth dispaly social login buttons?
     */
    static get propTypes() {
        return {
            allowSocialAuth: PropTypes.bool.isRequired,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            formFields: {
                firstName: '',
                secondName: '',
                email: '',
                country: userCountry,
                state: '',
                zipCode: '',
                password: '',
                confirmPassword: '',
                optIn: false,
                formFieldsInteraction: {}
            },
            isProcessing: false,
            stateList: [],
            countries: [],
            messages: [],
            token: '',
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onCheckHandler = this.onCheckHandler.bind(this);
        this.submitSignUp = this.submitSignUp.bind(this);
        this.getStateListData = this.getStateListData.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.handlePopUpFormField = this.handlePopUpFormField.bind(this);
    }

    componentDidMount() {
        api.getCountries().then((countries) => this.setState({ countries }));

        if (userCountry === 'AU' || userCountry === 'CA') {
            this.getStateListData(userCountry);
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pop_up_shown',
            pop_up_type: 'sign_up'
        });
    }

    componentWillUnmount() {
        window.dataLayer.push({
            event: 'pop_up_closed',
            pop_up_type: 'sign_up'
        });
    }

    onChangeHandler(event) {
        const field = event.target.name;
        const value = event.target.value;
        const { formFields, formFieldsInteraction } = this.state;
        if (!formFieldsInteraction?.[field]) {
            this.handlePopUpFormField(field);
            this.setState({
                formFieldsInteraction: {
                    ...formFieldsInteraction,
                    [field]: true,
                },
            });
        }

        if (field === 'country' && (value === 'AU' || value === 'CA')) {
            this.getStateListData(value);
        }

        if (field === 'country' && (formFields.zipCode || formFields.state)) {
            this.setState({
                formFields: Object.assign({}, formFields, {
                    [field]: value,
                    zipCode: '',
                    state: '',
                }),
            });
        } else {
            this.setState({
                formFields: Object.assign({}, formFields, {
                    [field]: value,
                }),
            });
        }
    }

    onCheckHandler(event) {
        const field = event.target.name;
        const { formFields } = this.state;

        this.setState({
            formFields: Object.assign({}, formFields, {
                [field]: event.target.checked,
            }),
        });
    }

    submitSignUp(event) {
        event.preventDefault();

        const { validateForm, trigger, onSuccess } = this.props;
        const { formFields } = this.state;
        let passwordMismatch = false;

        const form = event.target.form;

        if (formFields.password !== formFields.confirmPassword) {
            passwordMismatch = true;
            this.setState({
                isProcessing: false,
                messages: ['Your passwords do not match, please try again'],
            });
        }

        window.dataLayer.push({
            event: 'pop_up_submit',
            pop_up_type: 'sign_up'
        });

        validateForm(form).then((errors) => {
            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                if (errors[curr] !== '') {
                    acc += 1;
                }
                return acc;
            }, 0);

            if (numberOfErrors === 0 && !passwordMismatch) {
                this.setState({ isProcessing: true });
                api.getToken().then((token) => {
                    this.setState(
                        {
                            token: token.csrf_token,
                        },
                        () => {
                            api.signUp(formFields, this.state.token).then((response) => {
                                if (response.status === false) {
                                    const errorMessage = response.message.indexOf
                                        ? [response.message]
                                        : [...response.message];

                                    this.setState({
                                        isProcessing: false,
                                        messages: errorMessage,
                                    });
                                } else if (trigger) {
                                    $(`.${trigger}`).trigger('click');
                                } else {
                                    onSuccess();
                                    createCookie('loginChannel', LOGIN_CHANNEL_SMITH, 30);
                                }
                            });
                        }
                    );
                });
            }
        });
    }

    showMessage() {
        const { messages } = this.state;

        return messages.map((message) => (
            <p className="c-authentication__error" key={v4()}>
                {message}
            </p>
        ));
    }

    getStateListData(code) {
        api.getStateList(code).then((states) => {
            if (states !== false) {
                const stateList = Object.keys(states).reduce((acc, cur) => {
                    acc.push([cur, states[cur]]);
                    return acc;
                }, []);
                this.setState({ stateList });
            } else {
                this.setState({ stateList: [] });
            }
        });
    }

    handlePopUpFormField(fieldType) {
        window.dataLayer.push({
            event: 'pop_up_form_field',
            pop_up_type: 'sign_up',
            pop_up_field_name: fieldType
        });
    }

    renderSignUpForm() {
        const {
            formFields: { email, password, firstName, secondName, confirmPassword, country, state, zipCode, optIn },
            isProcessing,
            countries,
            stateList,
        } = this.state;
        const { onBlurValidation, formErrors } = this.props;

        const statePlaceHolder = country === 'CA' ? 'Province / Territory' : 'State';
        const showState = country === 'CA' || country === 'AU';
        const optInMessage = cpOptInMessage || MARKETING_OPT_IN_LABEL;

        return (
            <form className="c-signUpForm__form clearfix">
                <InputTypes
                    name="firstName"
                    value={firstName}
                    isRequired={true}
                    placeHolder="First name"
                    label="First name"
                    onChange={this.onChangeHandler}
                    onBlur={onBlurValidation}
                    error={formErrors.firstName}
                />
                <InputTypes
                    name="secondName"
                    value={secondName}
                    isRequired={true}
                    placeHolder="Second name"
                    label="Second name"
                    onChange={this.onChangeHandler}
                    onBlur={onBlurValidation}
                    error={formErrors.secondName}
                />
                <InputTypes
                    name="email"
                    value={email}
                    isRequired={true}
                    type="email"
                    placeHolder="Email address"
                    label="Email address"
                    onChange={this.onChangeHandler}
                    onBlur={onBlurValidation}
                    error={formErrors.email}
                />
                <SelectBox
                    name="country"
                    value={country}
                    isRequired={true}
                    onChange={this.onChangeHandler}
                    onBlur={onBlurValidation}
                    options={countries}
                    valueIndex={0}
                    placeholder="Country"
                    error={formErrors.country}
                />
                {country === 'US' && (
                    <InputTypes
                        name="zipCode"
                        value={zipCode}
                        isRequired={true}
                        placeHolder="ZIP code"
                        label="ZIP code"
                        onChange={this.onChangeHandler}
                        onBlur={onBlurValidation}
                        error={formErrors.zipCode}
                    />
                )}
                {showState && (
                    <SelectBox
                        name="state"
                        value={state}
                        onChange={this.onChangeHandler}
                        options={stateList}
                        valueIndex={0}
                        placeholder={statePlaceHolder}
                        isRequired={true}
                        error={formErrors.state && !state}
                    />
                )}
                <PasswordInput showValidationTooltip>
                    <InputTypes
                        name="password"
                        value={password}
                        type="password"
                        isRequired={true}
                        placeHolder="Password"
                        label="Password"
                        onChange={this.onChangeHandler}
                        onBlur={onBlurValidation}
                        error={formErrors.password}
                        maxlength={128}
                    />
                </PasswordInput>
                <PasswordInput showValidationTooltip>
                    <InputTypes
                        name="confirmPassword"
                        value={confirmPassword}
                        type="password"
                        isRequired={true}
                        placeHolder="Confim Password"
                        label="Confirm Password"
                        onChange={this.onChangeHandler}
                        onBlur={onBlurValidation}
                        error={formErrors.confirmPassword}
                        maxlength={128}
                    />
                </PasswordInput>
                <div className="c-signUpForm__checkbox">
                    <InputTypes
                        layout="inverted"
                        name="optIn"
                        value={optIn}
                        checked={optIn}
                        type="checkbox"
                        placeHolder={optInMessage}
                        label={optInMessage}
                        onChange={this.onCheckHandler}
                    />
                    <p className="c-form__instruction">{MARKETING_OPT_IN_TEXT}</p>
                </div>
                {this.showMessage()}
                <button className="c-authentication__cta" disabled={isProcessing} onClick={this.submitSignUp}>
                    Join now
                </button>
            </form>
        );
    }

    renderFooter() {
        const { changeFormAction } = this.props;
        return (
            <footer className="c-authentication__footer">
                <p className="c-authentication__footerText">Already a member?</p>
                <a className="c-authentication__footerAction" onClick={changeFormAction}>
                    Sign in
                </a>
            </footer>
        );
    }

    renderSocialAuth() {
        const { allowSocialAuth, onSuccess } = this.props;

        return (
            <Fragment>
                {allowSocialAuth && (
                    <Fragment>
                        <FacebookLogin onSuccess={onSuccess} mode={LoginModes.signup} />
                        <GoogleLogin onSuccess={onSuccess} mode={LoginModes.signup} />
                    </Fragment>
                )}
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                <section className="c-authentication__content">
                    <h2 className="c-authentication__title">Join for free</h2>
                    {this.renderSocialAuth()}
                    {this.renderSignUpForm()}
                </section>
                {this.renderFooter()}
            </Fragment>
        );
    }
}
