import React from 'react';
import {ButtonProps, IconsHelper, getButtonClassnames, wrapIcon} from './utilities/buttons';

export const Spinner = ({ size = 24 }: any) => {
    return (
        <svg
            width={size}
            height={size}
            className="fw-spinner"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            role="status"
        >
            <path fill="none" />
            <circle cx={50} cy={50} r={40} stroke="currentColor" fill="none" strokeWidth={10} strokeLinecap="round" />
            <circle cx={50} cy={50} r={40} stroke="currentColor" fill="none" strokeWidth={6} strokeLinecap="round">
                <animate attributeName="stroke-dashoffset" dur="1.3s" repeatCount="indefinite" from={0} to={502} />
                <animate
                    attributeName="stroke-dasharray"
                    dur="1.3s"
                    repeatCount="indefinite"
                    values="100.4 150.6;1 250;100.4 150.6"
                />
            </circle>
        </svg>
    );
};

export const getButtonContent = ({ icons, children }: IconsHelper, loading?: boolean) => (
    <>
        {icons && 'left' in icons && wrapIcon(icons.left, 'left')}
        {icons && 'center' in icons && wrapIcon(icons.center, 'center')}
        {children && <div className="content">{children}</div>}
        {icons && 'right' in icons && !loading && wrapIcon(icons.right, 'right')}
        {icons && 'right' in icons && loading && wrapIcon(<Spinner />, 'right')}
    </>
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ icons, children, displayStyle, size, loading, disabled, ...props }: ButtonProps, ref) => {
        const formattedClassName = getButtonClassnames({
            children,
            displayStyle,
            size,
            icons,
            loading,
            disabled,
            ...props,
        } as ButtonProps);

        return (
            <button type="button" {...props} disabled={disabled || loading} className={formattedClassName} ref={ref}>
                {getButtonContent({ children, icons } as ButtonProps, loading)}
            </button>
        );
    }
);

Button.displayName = 'Button';

export default Button;
