/**
 * Re-arrange the country list to have AU, GB and US at the top
 * @param {array} arrayOfCountries the array of countries
 * @return {array} the arranged array of countries
 */

const topCountriesList = ['AU', 'GB', 'US'];

const isTopCountry = curr => topCountriesList.indexOf(curr[0]) > -1;
const isNotTopCountry = curr => topCountriesList.indexOf(curr[0]) === -1;

export default (arrayOfCountries) => {
    const topCountries = arrayOfCountries.filter(isTopCountry);
    const restOfCountries = arrayOfCountries.filter(isNotTopCountry);
    return [...topCountries, ...restOfCountries];
};
