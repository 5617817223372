import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default WrappedComponent => class ApiData extends Component {
    static displayName = `withApiData(${WrappedComponent.displayName || WrappedComponent.name})`;

    /**
     * propTypes
     * @property {object} user the user
     * @property {function} getFavouriteLists passing back to the parent comp the favouriteLists
     */

    static get propTypes() {
        return {
            user: PropTypes.objectOf(PropTypes.any).isRequired,
        };
    }

    componentDidMount() {
        const { user, getFavouriteLists } = this.props;
        const isUserLoggedIn = user.reference !== false;

        if (isUserLoggedIn) {
            getFavouriteLists(user);
        }
    }

    render() {
        return (
            <WrappedComponent {...this.props} />
        );
    }
};
