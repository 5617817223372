import endpoints from '../constants/endpoints';
import objectToParams from '../../utilities/objectToParams';
import getFromDataLayer from '../../utilities/getFromDataLayer';
import router from '../../app/backend_router';
import postFormToEndpoint from '../../utilities/postFormToEndpoint';
import arrangeCountries from '../../utilities/arrangeCountries';

const siteTag = getFromDataLayer(window.dataLayer, 'siteTag');

export default {
    /**
     * API service for terms and conditions
     * @param  {object} hotelDetails
     * @return JSON data
     */
    getTerms(hotelDetails: any) {
        return fetch(router.generate(endpoints.rateterms, Object.assign({}, hotelDetails, { _format: 'json' })), {
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for quickview
     * @param  {object} roomCard
     * @return JSON data
     */
    getQuickView(roomCard: any) {
        return fetch(router.generate(endpoints.quickview, roomCard))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for gallery image fetcher
     * @param  {object} imageArgs
     * @return JSON data
     */
    getGalleryImages(imageArgs: any) {
        return fetch(router.generate(endpoints.gallery, imageArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for wishlist all hotels
     * @param  {object} userReference
     * @return JSON data
     */
    getWishlistAllHotels(userReference: any) {
        return fetch(router.generate(endpoints.wishlistAllhotels, userReference))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for wishlist all hotels
     * @param  {object} userReference
     * @return JSON data
     */
    getWishlist(userReference: any) {
        return fetch(router.generate(endpoints.wishlistGetlist, userReference))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for images
     * @param  {object} options
     * @return JSON data
     */
    getImages(options: any) {
        return fetch(router.generate(endpoints.images, options))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for autosuggest data
     * @param  {object} userReference
     * @return JSON data
     */
    getAutoSuggest(searchArgs: any) {
        return fetch(router.generate(endpoints.autosuggest, searchArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for combined autosuggest tags
     * @param  {object} tagArgs
     * @return JSON data
     */
    getCombinedTags(tagArgs: any) {
        return fetch(router.generate(endpoints.combinedtags, tagArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for map property details
     * @param  {object} mapArgs
     * @return JSON data
     */
    getMapPropertyDetail(mapArgs: any) {
        return fetch(router.generate(endpoints.mapGetPropertyDetail, mapArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for map availability
     * @param  {object} availArgs
     * @return JSON data
     */
    getMapAvailability(availArgs: any) {
        return fetch(router.generate(endpoints.mapGetAvailablilty, availArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for map data
     * @param  {object} allDataArgs
     * @return JSON data
     */
    getMapAllData(allDataArgs: any) {
        return fetch(router.generate(endpoints.mapGetAllData, allDataArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for shop prices
     * @param  {object} priceArgs
     * @return JSON data
     */
    getShopPrices(priceArgs: any) {
        return fetch(router.generate(endpoints.productPrices, priceArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for shop prices
     * @param  {object} priceArgs
     * @return JSON data
     */
    getShopMembershipPrices(priceArgs: any) {
        return fetch(router.generate(endpoints.productMembershipPrices, priceArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for basket count
     * @param  {object} basketFormat
     * @return JSON data
     */
    getBasketCount() {
        return fetch(router.generate(endpoints.basket, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },
    /**
     * API service for calendar availability
     * @param  {object} availArgs
     * @return JSON data
     */
    getCalendarAvailability(availArgs: any) {
        return fetch(router.generate(endpoints.availability, availArgs))
            .then((response) => response.json())
            .then((payload) => payload);
    },

    /**
     * Retrieve states in Australia, America, Canada
     *
     * @param {string} countryCode accepts au, us, ca
     */
    getStateList(countryCode: string | number | boolean) {
        const queryString = `&country=${encodeURIComponent(countryCode)}`;
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            headers,
            method: 'POST',
            body: queryString,
        };

        return fetch(endpoints.getStatesList, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    validateVoucher(formData: any) {
        const queryString = objectToParams(formData);
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            headers,
            method: 'POST',
            body: queryString,
            credentials: 'include',
        };

        return fetch(router.generate(endpoints.validateVoucher), fetchOptions).then((response) => {
            if (response.ok) {
                return response.json().then((payload) => payload);
            }
            return response.json().then((payload) => {
                throw new Error(payload.error);
            });
        });
    },

    getGiftLists() {
        return fetch(router.generate(endpoints.getGiftlists, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    createGiftList(formElements: { giftTitle: string | number | boolean; firstList: string | number | boolean; secondList: string | number | boolean; startDate: string | number | boolean; welcomeMessage: string | number | boolean; thanksMessage: string | number | boolean; }) {
        const queryParameters = [];

        queryParameters.push(`&title=${encodeURIComponent(formElements.giftTitle)}`);
        queryParameters.push(`&recipient_name=${encodeURIComponent(formElements.firstList)}`);
        queryParameters.push(`&second_recipient_name=${encodeURIComponent(formElements.secondList)}`);
        queryParameters.push(`&event_date=${encodeURIComponent(formElements.startDate)}`);
        queryParameters.push(`&welcome_message=${encodeURIComponent(formElements.welcomeMessage)}`);
        queryParameters.push(`&thank_you_message=${encodeURIComponent(formElements.thanksMessage)}`);

        const queryString = queryParameters.join('');

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            headers,
            method: 'PUT',
            body: queryString,
        };

        return fetch(router.generate(endpoints.createGiftlist), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    removeGiftList(giftListCode: string) {
        const url = `${router.generate(endpoints.removeGiftlist)}/${parseInt(giftListCode, 10)}`;

        const headers = new Headers({
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            method: 'DELETE',
            headers,
            credentials: 'include',
        };

        return fetch(url, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getGiftListContribute(giftListCode: any) {
        const fetchOptions: any = {
            credentials: 'include',
        };
        return fetch(router.generate(endpoints.getGiftListContribute, { giftListCode }), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload)
            .catch((err) => {
                throw err;
            });
    },

    getGiftListsDetail(giftListCode: string) {
        const params = { giftListCode: parseInt(giftListCode, 10) };

        const fetchOptions: any = {
            credentials: 'include',
        };

        return fetch(router.generate(endpoints.getGiftlistDetail, params), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    editGiftListsDetail(giftListCode: string, formElements: { giftTitle: string | number | boolean; firstList: string | number | boolean; secondList: string | number | boolean; startDate: string | number | boolean; welcomeMessage: string | number | boolean; thanksMessage: string | number | boolean; }) {
        const queryParameters = [];

        queryParameters.push(`&title=${encodeURIComponent(formElements.giftTitle)}`);
        queryParameters.push(`&recipient_name=${encodeURIComponent(formElements.firstList)}`);
        queryParameters.push(`&second_recipient_name=${encodeURIComponent(formElements.secondList)}`);
        queryParameters.push(`&event_date=${encodeURIComponent(formElements.startDate)}`);
        queryParameters.push(`&welcome_message=${encodeURIComponent(formElements.welcomeMessage)}`);
        queryParameters.push(`&thank_you_message=${encodeURIComponent(formElements.thanksMessage)}`);

        const queryString = queryParameters.join('');

        const params = { giftListCode: parseInt(giftListCode, 10) };

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            headers,
            method: 'PATCH',
            body: queryString,
        };

        return fetch(router.generate(endpoints.editGiftlistDetail, params), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getWishLists() {
        return fetch(router.generate(endpoints.getWishlists, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    createWishList(wishlistElements: { wishlistTitle: string | number | boolean; description: string | number | boolean; }) {
        const queryParameters = [];

        queryParameters.push(`&title=${encodeURIComponent(wishlistElements.wishlistTitle)}`);
        queryParameters.push(`&description=${encodeURIComponent(wishlistElements.description)}`);

        const queryString = queryParameters.join('');

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            headers,
            method: 'PUT',
            body: queryString,
        };

        return fetch(router.generate(endpoints.createWishlist), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getLoyalty() {
        return fetch(router.generate(endpoints.getLoyalty, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getWishListDetail(url: URL) {
        const fetchOptions: RequestInit = {
            credentials: 'include',
        };
        
        const apiURL = new URL(url);

        apiURL.pathname += '/get';

        return fetch(apiURL, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    removeWishlist(url: RequestInfo | URL) {
        const headers = new Headers({
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            method: 'DELETE',
            headers,
            credentials: 'include',
        };

        return fetch(url, fetchOptions);
    },

    editWishListsDetail(url: RequestInfo | URL, wishlistTitle: string | number | boolean, description: string | number | boolean) {
        const queryParameters = [];

        queryParameters.push(`&title=${encodeURIComponent(wishlistTitle)}`);
        queryParameters.push(`&description=${encodeURIComponent(description)}`);

        const queryString = queryParameters.join('');

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            headers,
            method: 'PATCH',
            body: queryString,
        };

        return fetch(url, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    addItemToFavourites(data: any) {
        const queryString = objectToParams(data);

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            method: 'POST',
            headers,
            credentials: 'include',
            body: queryString,
        };

        return fetch(router.generate(endpoints.addItemToFavourites), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    removeItemFromFavourites(data: any) {
        const queryString = objectToParams(data);

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            method: 'POST',
            headers,
            credentials: 'include',
            body: queryString,
        };

        return fetch(router.generate(endpoints.removeItemFromFavourites), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    submitVote(formFields: any) {
        const queryString = objectToParams(formFields);

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: queryString,
        };

        return fetch(router.generate(endpoints.awardVote), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    dataCapture(formFields: any, slug: any) {
        const endpoint = `${endpoints.dataCapture}/${slug}`;
        return postFormToEndpoint(formFields, endpoint);
    },

    getCountries() {
        return fetch(router.generate(endpoints.getCountries))
            .then((response) => response.json())
            .then((payload) => {
                const countriesList = Object.keys(payload).reduce((acc, curr) => {
                    acc.push([curr, payload[curr]]);
                    return acc;
                }, []);
                return arrangeCountries(countriesList);
            });
    },

    checkLogin(formField: { email: any; password: any; remember: any; }) {
        let remember = 1;
        const queryParameters = [];

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        if (formField.remember === false) {
            remember = 0;
        } else {
            remember = 1;
        }

        queryParameters.push(`&LoginType[email]=${encodeURIComponent(formField.email)}`);
        queryParameters.push(`&LoginType[password]=${encodeURIComponent(formField.password)}`);
        queryParameters.push(`&_remember_me=${encodeURIComponent(remember)}`);

        const queryString = queryParameters.join('');

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: queryString,
        };

        return fetch(router.generate(endpoints.checkLogin, { site_tag: siteTag }), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload)
            .catch((err) => {
                throw err;
            });
    },

    getToken() {
        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
        };

        return fetch(router.generate(endpoints.getToken), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    signUp(formField: {
        optIn: boolean,
        firstName: string,
        secondName: string,
        password: string,
        confirmPassword: string,
        country: string,
        email: string,
        zipCode: string,
        state: string,
    }, token: string | number | boolean) {
        const queryParameters = [];
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const optIn = formField.optIn === false ? 0 : 1;
        const optinParam = optIn ? `&SignupMemberType[optin]=${encodeURIComponent(optIn)}` : '';

        queryParameters.push(`&SignupMemberType[firstname]=${encodeURIComponent(formField.firstName)}`);
        queryParameters.push(`&SignupMemberType[lastname]=${encodeURIComponent(formField.secondName)}`);
        queryParameters.push(`&SignupMemberType[password][NewPassword]=${encodeURIComponent(formField.password)}`);
        queryParameters.push(
            `&SignupMemberType[password][ConfirmPassword]=${encodeURIComponent(formField.confirmPassword)}`
        );
        queryParameters.push(`&_token=${encodeURIComponent(token)}`);
        queryParameters.push(`&SignupMemberType[country]=${encodeURIComponent(formField.country)}`);
        queryParameters.push(
            `&SignupMemberType[identities][new-identity][email]=${encodeURIComponent(formField.email)}${optinParam}`
        );
        queryParameters.push(
            `&SignupMemberType[addresses][new-address][postcode]=${encodeURIComponent(formField.zipCode)}`
        );
        queryParameters.push(`&SignupMemberType[addresses][new-address][state]=${encodeURIComponent(formField.state)}`);

        const queryString = queryParameters.join('');

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: queryString,
        };

        return fetch(router.generate(endpoints.signUp), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    forgotPassword(formField: { email: any; }, token: string | number | boolean) {
        const queryString = `&EmailCollectionType[email]=${encodeURIComponent(
            formField.email
        )}&_token=${encodeURIComponent(token)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: queryString,
        };

        return fetch(router.generate(endpoints.forgotPassword), fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    socialLogin(token: any, authentificationType: string) {
        const queryParameters = [];
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        queryParameters.push(`&id_token=${token}`);
        queryParameters.push(`&authentication_type=${authentificationType}`);
        const queryString = queryParameters.join('');

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: queryString,
        };
        const url = router.generate(endpoints.socialLogin);

        return fetch(`${url}/${authentificationType}`, fetchOptions).then((response) => response.json());
    },

    getBookingsDetails() {
        return fetch(router.generate(endpoints.getBookingsDetails, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getBookingsPagination(url: RequestInfo | URL) {
        const fetchOptions: any = {
            credentials: 'include',
        };

        return fetch(url, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getAllGuestbooks() {
        return fetch(router.generate(endpoints.getGuestbooks, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getShoppingOffers() {
        return fetch(router.generate(endpoints.getShoppingOffers, { _format: 'json' }), { credentials: 'include' })
            .then((response) => response.json())
            .then((payload) => payload);
    },

    addGuestbookEntryAction(formElements: { perfect: string | any[]; pros: string | number | boolean; cons: string | number | boolean; recommendations: string | number | boolean; children: string | number | boolean; rating: string | number | boolean; link: any; }) {
        let queryString = '';
        let perfectItems = '';

        for (let i = 0; i < formElements.perfect.length; i++) {
            perfectItems += `&perfect_fors[]=${formElements.perfect[i]}`;
        }

        queryString = `&guestbook_pros=${encodeURIComponent(formElements.pros)}&guestbook_cons=${encodeURIComponent(
            formElements.cons
        )}&guestbook_outside=${encodeURIComponent(
            formElements.recommendations
        )}&guestbook_children=${encodeURIComponent(
            formElements.children
        )}${perfectItems}&guestbook_rating=${encodeURIComponent(formElements.rating)}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            headers,
            method: 'PUT',
            body: queryString,
        };

        return fetch(`${formElements.link}`, fetchOptions)
            .then((response) => response.json())
            .then((payload) => payload);
    },

    getInclusiveDetails(urlName: any) {
        return fetch(router.generate(endpoints.getAllInclusiveDetails, { urlName })).then((response) =>
            response.json()
        );
    },
    getFreeCancellationText(link: RequestInfo | URL) {
        return fetch(link).then((response) => response.json());
    },
    getBookingCancellationData(bookingReference: any) {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Request-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            headers,
            method: 'GET',
        };

        return fetch(router.generate(endpoints.bookingCancellationData, { bookingReference: bookingReference }), fetchOptions)
            .then((response) => response.json())
            .catch((err) => {
                throw err;
            });
    },
    cancelBooking(bookingId: any, itemsIds: { toString: () => any; }) {
        const lineItemsString = itemsIds.toString();
        const data = `BookingId=${bookingId}&LineItemIds=${lineItemsString}`;

        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Request-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            credentials: 'include',
            method: 'POST',
            headers,
            body: data
        }

        return fetch(router.generate(endpoints.cancelLineItemsForBooking), fetchOptions)
            .then((response) => response.json())
            .catch((err) => {
                throw err;
            });
    },

    getEditableBanner() {
        const headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Request-With': 'XMLHttpRequest',
        });

        const fetchOptions: any = {
            headers,
            method: 'GET',
        };

        return fetch(router.generate(endpoints.editableBanner, { section: 'homepage' }), fetchOptions)
            .then(response => response.json())
            .then(payload => payload);
    },
};
