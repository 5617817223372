export default {
    // myAccount
    getPersonalDetailsPage: 'smith_membershub_my_account_personal_details_api',
    savePersonalDetails: 'smith_membershub_my_account_personal_details_update',
    getAddresses: 'smith_membershub_my_account_address_api',
    saveAddressDetails: 'smith_membershub_my_account_address_create',
    updateAddressDetails: 'smith_membershub_my_account_address_update',
    deleteAddressDetails: 'smith_membershub_my_account_address_delete',
    getAccountPasswordPage: 'smith_membershub_my_account_password_api',
    submitPasswordChange: 'smith_membershub_my_account_password_update',
    getPreferencesPage: 'smith_membershub_my_account_preferences_api',
    savePreferences: 'smith_membershub_my_account_preferences_update',
    getReminders: 'smith_membershub_my_account_reminders_api',
    createReminders: 'smith_membershub_my_account_reminders_create',
    getCustomerDetails: 'smith_membershub_my_account_reminders_contacts',
    deleteReminder: 'smith_membershub_my_account_reminders_delete',
    updateReminder: 'smith_membershub_my_account_reminders_update'
};
